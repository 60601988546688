import NewPetition from '@Components/Petitions/New'
import PetitionDetails from '@Components/Petitions/Details/PetitionDetails'
import PetitionList from '@Components/Petitions/List'
import { HomePage } from '@Views/Home'
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PetitionForm from '@Components/Petitions/New/Form'
import RoleRoute from '@Components/RoleRoute'
import Manage from '@Components/Petitions/Management'
import AcceptOrDecline from '@Components/Petitions/AcceptOrDecline'
import { routes } from './routes'

const PrivateRoutes = () => (
  <Switch>
    <Route exact path={routes.base} component={HomePage} />
    <RoleRoute
      exact
      path={routes.petitionsList}
      requiredRoles={['petitioner', 'manager']}
      component={PetitionList}
    />
    <RoleRoute
      exact
      path={routes.petitionManageBase}
      requiredRoles={['petitioner', 'manager']}
      component={() => <Redirect to={routes.petitionsList} />}
    />
    <RoleRoute
      exact
      path={routes.petitionsNew}
      requiredRoles={['petitioner']}
      component={PetitionForm}
    />
    <RoleRoute
      exact
      path={routes.petitionStepper}
      requiredRoles={['petitioner']}
      component={NewPetition}
    />
    <RoleRoute
      exact
      path={routes.petitionAccept}
      requiredRoles={['petitioner']}
      component={AcceptOrDecline}
    />
    <RoleRoute
      exact
      path={routes.petitionManage}
      requiredRoles={['manager']}
      component={Manage}
    />
    <RoleRoute
      exact
      path={routes.petitionDetails}
      requiredRoles={['petitioner', 'manager']}
      component={PetitionDetails}
    />
    {process.env.NODE_ENV === 'development' && (
      <Route exact path="/dev" component={AcceptOrDecline} />
    )}
  </Switch>
)

export default PrivateRoutes
