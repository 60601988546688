import AssignmentCalendar from '@Components/AssignmentCalendar'
import AssignmentFilter from '@Components/AssignmentFilter'
import AssignmentKnowledge from '@Components/AssignmentKnowledge'
import Loading from '@Components/Loading'
import {
  Category,
  ManagePetitionQuery,
  useSuggestSpecialistQuery,
} from '@Generated/staffing-hooks'
import { Col, Row, Typography } from 'antd'
import moment from 'moment'
import { reject } from 'ramda'
import React, { useEffect, useState } from 'react'

type AssignmentProps = {
  data: ManagePetitionQuery
}

const Assignment = ({ data }: AssignmentProps) => {
  const [current, setCurrent] = useState(0)
  const [myData, setData] = useState(
    data.petition.intervals.edges.map(edge => ({
      area: edge.node.knowledge.name,
      areaID: edge.node.knowledge.id,
      categories: [edge.node.category as string],
      skills: edge.node.skills.edges.map(({ node: { id, name } }) => ({
        id,
        name,
      })),
    }))
  )

  const { data: suggestion, loading, refetch } = useSuggestSpecialistQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        catergory: myData[current].categories as Category[],
        skills: myData[current].skills.map(skill => skill.id),
        petition: data.petition.id,
      },
    },
  })

  useEffect(() => {
    refetch({
      input: {
        catergory: myData[current].categories as Category[],
        skills: myData[current].skills.map(skill => skill.id),
        petition: data.petition.id,
      },
    })
  }, [current, data, data.petition.id, myData, refetch])

  if (loading) {
    return <Loading />
  }

  if (suggestion) {
    return (
      <>
        <Row style={{ paddingBottom: 10, paddingTop: 20 }}>
          <Col offset={4}>
            <Typography.Title
              style={{
                fontSize: '22px',
              }}
            >
              {`Área de ${myData[current].area.charAt(0).toUpperCase()}${myData[
                current
              ].area.substring(1)}`}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <AssignmentKnowledge
              activeArea={current}
              onChage={index => setCurrent(index)}
              knowledgeAreas={data?.petition.intervals.edges}
            />
          </Col>
          <Col md={20}>
            <AssignmentFilter
              knowledgeArea={myData[current]}
              onChange={(type, state) => {
                const currentArea = myData[current]
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                currentArea[type] = state
                setData(prev => {
                  const newPrev = { ...prev }
                  newPrev[current] = currentArea
                  return newPrev
                })
              }}
              onSkillRemove={skill => {
                const currentArea = myData[current]
                currentArea.skills = reject(
                  currentSkill => currentSkill.id === skill.id,
                  currentArea.skills
                )
                setData(prev => {
                  const newPrev = { ...prev }
                  newPrev[current] = currentArea
                  return newPrev
                })
              }}
              onCategoryRemove={category => {
                const currentArea = myData[current]
                currentArea.categories = reject(
                  currentSkill => currentSkill === category,
                  currentArea.categories
                )
                setData(prev => {
                  const newPrev = { ...prev }
                  newPrev[current] = currentArea
                  return newPrev
                })
              }}
            />
            <AssignmentCalendar
              name={`proposal[${current}]`}
              candidates={suggestion.suggestSpecialists.map(specialist => ({
                id: specialist.user.id,
                name: specialist.user.name,
                rank: specialist.user.category as string,
                selectedDays: [],
                coincidenceSkills: Math.floor(
                  specialist.points.skillsMatch * myData[current].skills.length
                ),
                engagedDays: specialist.points.assignamentDays.map(day =>
                  moment(day)
                ),
              }))}
              startDate={moment(
                data.petition.intervals.edges[current].node.start
              )}
              endDate={moment(data.petition.intervals.edges[current].node.end)}
              requiredDays={
                data.petition.intervals.edges[current].node.duration
              }
              maxSkills={myData[current].skills.length}
            />
          </Col>
        </Row>
      </>
    )
  }
  return <>Error</>
}

export default Assignment
