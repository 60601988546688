import { DownloadFile } from '@Components/Upload'
import { Card, Col, Row, Table } from 'antd'
import UploadList from 'antd/lib/upload/UploadList'
import { useFormikWizard } from 'formik-wizard'
import React from 'react'
import GetAreas from '@Utils/getKnowledge'
import StepSummary from '@Components/StepSummary'
import columns from './columns'
import './index.less'

const Resumen = () => {
  const { values } = useFormikWizard()
  const data = values.configuration.team

  return (
    <Row>
      <StepSummary
        endDate={values.configuration.endDate}
        teamSize={values.configuration.team.length}
        knowledgeAreas={GetAreas(values.configuration.team)}
        link="Mi Hospital Fácil Fase 1"
      />
      <Table pagination={false} columns={columns} dataSource={data} />
      <Row className="sf-comment">
        <Col span={16}>
          <Card className="sf-comment-right">
            {values.documentation.comment}
          </Card>
        </Col>
        <Col span={8}>
          <UploadList
            showRemoveIcon={false}
            onDownload={DownloadFile}
            onPreview={DownloadFile}
            listType="text"
            locale={{}}
            items={values.documentation.files}
          />
        </Col>
      </Row>
    </Row>
  )
}

export default Resumen
