import { Layout } from 'antd'
import React from 'react'
import Logout from '@Components/Logout'
import './index.less'

const { Header: AntdHeader } = Layout

const Header = () => {
  return (
    <AntdHeader className="sf-header">
      <Logout />
    </AntdHeader>
  )
}

export default Header
