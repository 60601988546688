import {
  useAddProposalToPetitionMutation,
  useAddPetitonActionMutation,
  ActionType,
} from '@Generated/staffing-hooks'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from '@Routes/routes'
import Stepper from '../New/Stepper'
import { ManagementFormikWizardContext } from './Resumen'
import steps from './steps'

const Manage = () => {
  const { state } = useLocation()
  const [submitProposal] = useAddProposalToPetitionMutation()
  const [addComment] = useAddPetitonActionMutation()
  const history = useHistory()

  return (
    <>
      <Stepper
        id={state.id}
        steps={steps}
        formikProps={{
          validateOnBlur: false,
          validateOnChange: false,
          validateOnMount: false,
        }}
        onSubmit={async (values: ManagementFormikWizardContext) => {
          const { data } = await submitProposal({
            variables: {
              petition: state.id,
              input: {
                assignaments: values.configuration.proposal
                  .flat()
                  .filter(candidate => candidate.selectedDays.length > 0)
                  .map(candidate => ({
                    expert: candidate.id,
                    days: candidate.selectedDays.map(day => day.toISOString()),
                  })),
              },
            },
          })
          addComment({
            variables: {
              draft: state.id,
              input: {
                type: ActionType.PENDING_TO_REVIEW_PROPOSAL,
                comment: values.documentation.comment,
              },
            },
          })
          if (data) {
            history.push(routes.petitionsList)
          }
        }}
      />
    </>
  )
}

export default Manage
