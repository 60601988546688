import { useContext } from 'react'
import { AuthContext } from '@Context/auth'
import JwtDecode from 'jwt-decode'

type TokenDTO = {
  iat: number
  exp: number
  id: string
  roles: string[]
}

export const useAuth = () => {
  const { signIn, userToken, signOut, isLoading } = useContext(AuthContext)
  let isTokenValid: boolean
  let roles: string[] = []
  try {
    const { exp, roles: roleList } = JwtDecode<TokenDTO>(userToken as string)
    isTokenValid = exp > Date.now() / 1000
    roles = roleList
  } catch {
    isTokenValid = false
  }
  return { signIn, isTokenValid, signOut, isLoading, roles }
}
