import { ActionType, Status } from '@Generated/staffing-hooks'

const commonValues = {
  [ActionType.APROVED]: 'Aprobado',
  [ActionType.CANCELED]: 'Cancelado',
  [ActionType.PENDING_TO_ASSIGNAMENT]: 'Pendiente de asignar',
  [ActionType.PENDING_TO_REVIEW_PROPOSAL]: 'Pendiente de revisar',
}

export const statusValues = {
  ...commonValues,
  [Status.DRAFT]: 'Borrador',
}

export const actionTypeValues = {
  ...commonValues,
  [ActionType.COMMENT]: 'Comentario',
  [ActionType.CREATED]: 'Creado',
}
