import React, { useEffect, useMemo, useReducer, ReactNode } from 'react'

type AuthContextAction = {
  type: 'RESTORE_TOKEN' | 'SIGN_IN' | 'SIGN_OUT'
  token: string | null | undefined
}

type AuthContextState = {
  isLoading: boolean
  userToken: string | null | undefined
}

type AuthContextType = {
  signIn: (token: string) => void
  signOut: () => void
  isLoading: boolean
  userToken: string | null | undefined
}

const authReducer = (
  prevState: AuthContextState,
  action: AuthContextAction
) => {
  switch (action.type) {
    case 'RESTORE_TOKEN': {
      return {
        userToken: action.token,
        isLoading: false,
      }
    }
    case 'SIGN_IN':
      return {
        ...prevState,
        userToken: action.token,
      }
    case 'SIGN_OUT':
      return {
        ...prevState,
        userToken: action.token,
      }
    default:
      return prevState
  }
}

export const AuthContext = React.createContext({} as AuthContextType)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isLoading: true,
    userToken: null,
  })

  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    dispatch({ type: 'RESTORE_TOKEN', token: userToken })
  }, [])

  const authContext = useMemo(
    () => ({
      signIn: (token: string) => {
        localStorage.setItem('userToken', token)
        dispatch({ type: 'SIGN_IN', token })
      },
      signOut: () => {
        localStorage.removeItem('userToken')
        dispatch({ type: 'SIGN_OUT', token: null })
      },
      isLoading: state.isLoading,
      userToken: state.userToken,
    }),
    [state.isLoading, state.userToken]
  )

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  )
}
