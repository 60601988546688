import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { Radio } from 'formik-antd'
import { Button, Modal, Row, Divider, Typography, Form } from 'antd'
import { useClientPetitionsLazyQuery } from '@Generated/staffing-hooks'
import './index.less'

const { Item } = Form

const TicketToExtend = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [getProjects, { data }] = useClientPetitionsLazyQuery()

  const [modal, setModal] = useState(false)
  const [option, setOption] = useState(values.linked)

  const closeModal = () => {
    setModal(!modal)
    setFieldValue('linked', option)
  }

  return (
    <>
      <Item required label="Vinculación">
        <Button
          disabled={values.client === null}
          style={{ padding: 0 }}
          type="link"
          onClick={() => {
            const variables = {
              id: values.client,
            }
            getProjects({ variables })
            setModal(!modal)
          }}
        >
          Seleccionar el ticket que quieres ampliar
        </Button>
      </Item>
      <Modal
        title={
          <Row type="flex" justify="center" className="sf-title-row">
            {/* eslint-disable-next-line no-restricted-globals */}
            {data?.client.name}
          </Row>
        }
        visible={modal}
        centered
        closable={false}
        footer={
          <Row type="flex" justify="center">
            <Button
              key="back"
              type="link"
              className="sf-on-cancel"
              onClick={closeModal}
            >
              Cancelar
            </Button>
            <Button type="link" key="submit" onClick={closeModal}>
              Añadir proyecto
            </Button>
          </Row>
        }
      >
        <Radio.Group
          name="linked"
          onChange={({ target }) => setOption(target.value)}
          className="sf-modal-content"
        >
          {data?.client.petitions.edges.map((e, idk, array) => {
            return (
              <div key={idk}>
                <Row type="flex" justify="space-between">
                  <Radio name="linked" value={e}>
                    {e.node?.project?.name}
                  </Radio>
                  <Typography.Text>Proyecto</Typography.Text>
                </Row>
                {idk !== array.length - 1 && <Divider />}
              </div>
            )
          })}
        </Radio.Group>
      </Modal>
    </>
  )
}

export default TicketToExtend
