import React from 'react'
import { FormikWizard } from 'formik-wizard'
import { FormikWizardProps } from 'formik-wizard/dist/types'
import { Row, Steps, Divider, Button, Col } from 'antd'

import './index.less'
import Alert from '@Components/Alert'

type StepperProps<Values, Status> = Pick<
  FormikWizardProps<Values, Status>,
  'steps' | 'onSubmit' | 'formikProps'
>

export default function Stepper<Values, Status = {}>({
  steps,
  onSubmit,
  id,
  formikProps,
}: StepperProps<Values, Status> & { id: string }) {
  return (
    <FormikWizard
      steps={steps}
      formikProps={formikProps}
      onSubmit={onSubmit}
      render={({
        // eslint-disable-next-line no-shadow
        steps,
        currentStep,
        children,
        goToPreviousStep,
        isLastStep,
        canGoBack,
      }) => (
        <>
          <Row type="flex" justify="center">
            <Steps current={steps.indexOf(currentStep)}>
              {steps.map(item => (
                <Steps.Step key={item} title={item} />
              ))}
            </Steps>
          </Row>
          <Divider />
          <Alert id={id} />
          <Row>{children}</Row>
          <Row
            type="flex"
            justify="end"
            className="sf-stepper-actions"
            gutter={[16, 8]}
          >
            {canGoBack && (
              <Col>
                <Button onClick={goToPreviousStep}>Volver</Button>
              </Col>
            )}
            {!isLastStep && (
              <Col>
                <Button htmlType="submit" type="primary">
                  Continuar
                </Button>
              </Col>
            )}
            {isLastStep && (
              <Col>
                <Button htmlType="submit" type="primary">
                  Enviar
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
    />
  )
}
