import React from 'react'
import { DatePicker, Form } from 'formik-antd'
import { FieldArray, useFormikContext } from 'formik'
import { Table, Button, Row, Typography, Icon } from 'antd'
import uniqid from 'uniqid'
import moment from 'moment'
import { useKnowledgeSkillsQuery } from '@Generated/staffing-hooks'
import ExpandedRowSkills from './ExpandedRowSkills'
import { columns } from './columns'
import './index.less'

const { Item } = Form

const teamsInitialValue = {
  key: uniqid(),
  area: undefined,
  specialist: undefined,
  days: undefined,
  assignamentStart: undefined,
  skills: [],
}

type Team = typeof teamsInitialValue

const Configuration = () => {
  const { values } = useFormikContext<any>()
  const { data } = useKnowledgeSkillsQuery()

  const disabledDate = (current: any) => {
    return (
      current &&
      current <
        moment()
          .endOf('day')
          .add(-1, 'day')
    )
  }

  const disabledAddSpecialist = (teams: Team[]) =>
    teams.some(
      team =>
        team.area === undefined ||
        team.specialist === undefined ||
        team.days === undefined ||
        team.assignamentStart === undefined
    )

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  }

  return (
    <>
      <Row
        type="flex"
        align="middle"
        justify="start"
        className="sf-asign-project-config"
      >
        <Item
          name="endDate"
          label="Fin del proyecto"
          required
          {...formItemLayout}
        >
          <DatePicker disabledDate={disabledDate} name="endDate" />
        </Item>
      </Row>
      <FieldArray name="team">
        {helpers => (
          <>
            <Table
              dataSource={values.team}
              pagination={false}
              columns={columns({
                helpers,
                project: '',
                data,
              })}
              expandIcon={({ expanded, onExpand }) => (
                <Icon type={expanded ? 'down' : 'right'} onClick={onExpand} />
              )}
              expandedRowRender={(_record, index) => (
                <ExpandedRowSkills
                  skillsPath={`team[${index}].skills`}
                  areaPath={`team[${index}].area`}
                />
              )}
              footer={() => (
                <Button
                  type="dashed"
                  onClick={() => helpers.push(teamsInitialValue)}
                  disabled={disabledAddSpecialist(values.team)}
                >
                  <Typography>
                    <Icon type="plus" /> Añadir Especialista
                  </Typography>
                </Button>
              )}
            />
          </>
        )}
      </FieldArray>
    </>
  )
}

export default Configuration
