import React, { ReactNode, useReducer, useMemo, useEffect } from 'react'
import moment, { Moment } from 'moment'
import clonedeep from 'lodash.clonedeep'
import { useFormikContext } from 'formik'

type AssignamentContextType = {
  addDayToCandidate: (index: number, day: moment.Moment) => void
  removeDayFromCandidate: (index: number, day: moment.Moment) => void
  updateCalendarType: () => void
  updateCurrentDate: (date: moment.Moment) => void
  calendar: AssignamentContextState
}

type Candidate = {
  id: string
  name: string
  rank: string
  coincidenceSkills: number
  selectedDays: Moment[]
  engagedDays: Moment[]
}

export type CalendarType = 'month' | 'week'
export type CalendarMode = 'view' | 'editable'

export type AssignamentCalendarProps = {
  candidates: Candidate[]
  startDate: Moment
  endDate: Moment
  requiredDays?: number
  selectedDays?: number
  calendarType?: CalendarType
  mode?: CalendarMode
  maxSkills?: number
  name?: string
}

type AssignamentContextState = {
  candidates: Candidate[]
  startDate: Moment
  endDate: Moment
  currentDate: Moment
  requiredDays: number
  selectedDays: number
  calendarType: CalendarType
  mode: CalendarMode
  maxSkills: number
}

type AssignamentCalendarProviderProps = {
  children: ReactNode
  value: AssignamentCalendarProps
}

interface Action {
  type: string
}

interface UpdateCandidateEngagedDays extends Action {
  payload: {
    index: number
    operation: 'add' | 'remove'
    day: Moment
  }
}

interface UpdateCurrentDate extends Action {
  payload: Moment
}

type UpdateCalendarType = Action

type ActionType =
  | UpdateCandidateEngagedDays
  | UpdateCurrentDate
  | UpdateCalendarType

function isAddCandidateSelectedDays(
  action: Action
): action is UpdateCandidateEngagedDays {
  return action.type === 'UPDATE_CANDIDATE_DAYS'
}

function isUpdateCurrentDate(action: Action): action is UpdateCurrentDate {
  return action.type === 'UPDATE_CURRENT_DATE'
}

function isUpdateCalendarType(action: Action): action is UpdateCalendarType {
  return action.type === 'UPDATE_CALENDAR_TYPE'
}

export const AssignamentContext = React.createContext(
  {} as AssignamentContextType
)

const assignamentCalendarReducer = (
  prev: AssignamentContextState,
  action: ActionType
): AssignamentContextState => {
  if (isAddCandidateSelectedDays(action)) {
    const candidates = [...prev.candidates]
    if (action.payload.operation === 'add') {
      candidates[action.payload.index].selectedDays.push(action.payload.day)
      return {
        ...prev,
        candidates,
        selectedDays: prev.selectedDays + 1,
      }
    }
    candidates[action.payload.index].selectedDays.filter(
      selected => !selected.isSame(action.payload.day)
    )
    return {
      ...prev,
      candidates,
      selectedDays: prev.selectedDays - 1,
    }
  }
  if (isUpdateCurrentDate(action)) {
    return {
      ...prev,
      currentDate: action.payload,
    }
  }
  if (isUpdateCalendarType(action)) {
    return {
      ...prev,
      calendarType: prev.calendarType === 'month' ? 'week' : 'month',
    }
  }
  return prev
}

const AssignamentCalendarProvider = ({
  children,
  value: {
    selectedDays = 0,
    calendarType = 'month',
    name,
    mode = 'editable',
    maxSkills = 0,
    requiredDays = 0,
    ...values
  },
}: AssignamentCalendarProviderProps) => {
  const { setFieldValue } = useFormikContext<any>()
  const [state, dispatch] = useReducer(assignamentCalendarReducer, {
    selectedDays,
    calendarType,
    requiredDays,
    mode,
    maxSkills,
    currentDate: moment.max(moment(), values.startDate),
    ...values,
  })

  const calendarContext = useMemo(
    () => ({
      addDayToCandidate: (index: number, day: Moment) => {
        dispatch({
          type: 'UPDATE_CANDIDATE_DAYS',
          payload: { index, day, operation: 'add' },
        })
      },
      removeDayFromCandidate: (index: number, day: Moment) => {
        dispatch({
          type: 'UPDATE_CANDIDATE_DAYS',
          payload: { index, day, operation: 'remove' },
        })
      },
      updateCalendarType: () => {
        dispatch({ type: 'UPDATE_CALENDAR_TYPE' })
      },
      updateCurrentDate: (date: Moment) => {
        dispatch({ type: 'UPDATE_CURRENT_DATE', payload: date })
      },
      calendar: clonedeep(state),
    }),
    [state]
  )

  useEffect(() => {
    if (name) {
      setFieldValue(name, state.candidates, false)
    }
  }, [name, setFieldValue, state.candidates])

  useEffect(() => {
    if (mode === 'editable') {
      setFieldValue('requiredDays', state.requiredDays)
      setFieldValue('selectedDays', state.selectedDays)
    }
  }, [mode, setFieldValue, state.requiredDays, state.selectedDays])

  return (
    <AssignamentContext.Provider value={calendarContext}>
      {children}
    </AssignamentContext.Provider>
  )
}

export default AssignamentCalendarProvider
