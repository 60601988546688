import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Select, InputNumber, DatePicker, Form } from 'formik-antd'
import { ArrayHelpers } from 'formik/dist/FieldArray'
import { Popover, Icon } from 'antd'
import RowActionsMenu from '@Components/Petitions/New/RowActionsMenu'
import moment from 'moment'
import { KnowledgeSkillsQuery } from '@Generated/staffing-hooks'

const { Option } = Select
const { Item } = Form

type ColumnParams = {
  project: string
  helpers: ArrayHelpers
  data: KnowledgeSkillsQuery | undefined
}

export const columns = ({
  project,
  helpers,
  data,
}: ColumnParams): ColumnProps<any>[] => {
  const disabledDate = (current: any) => {
    return (
      current &&
      current <
        moment()
          .endOf('day')
          .add(-1, 'day')
    )
  }
  return [
    {
      title: (
        <>
          Equipo para proyecto <a href="/#">{project}</a>
        </>
      ),
      dataIndex: 'area',
      render(_text, _record, index) {
        return (
          <Item name={`team[${index}].area`}>
            <Select name={`team[${index}].area`} placeholder="Selecciona Área">
              {data?.knowledge.edges.map(e => (
                <Option key={e.node.id}>{e.node.name}</Option>
              ))}
            </Select>
          </Item>
        )
      },
    },
    {
      title: 'Especialista',
      dataIndex: 'specialist',
      render(_text, _record, index) {
        return (
          <Item name={`team[${index}].specialist`}>
            <Select placeholder="Categoría" name={`team[${index}].specialist`}>
              {data?.specialistCategory.map(e => (
                <Option key={e}>{e}</Option>
              ))}
            </Select>
          </Item>
        )
      },
    },
    {
      title: 'Días',
      dataIndex: 'days',
      render(_text, _record, index) {
        return (
          <Item name={`team[${index}].days`}>
            <InputNumber
              placeholder="00"
              name={`team[${index}].days`}
              min={1}
            />
          </Item>
        )
      },
    },
    {
      title: 'Inicio Asignación',
      dataIndex: 'assignamentStart',
      render(_text, _record, index) {
        return (
          <Item name={`team[${index}].assignamentStart`}>
            <DatePicker
              disabledDate={disabledDate}
              name={`team[${index}].assignamentStart`}
            />
          </Item>
        )
      },
    },
    {
      dataIndex: 'more',
      render(_text, record, index) {
        return (
          <Popover
            placement="left"
            content={
              <RowActionsMenu index={index} record={record} helpers={helpers} />
            }
            trigger="click"
          >
            <Icon type="more" style={{ fontSize: '18px' }} />
          </Popover>
        )
      },
    },
  ]
}
