import React from 'react'
import RateInput from '../RateInput'
import LinkProject from '../LinkProject'
import { useFormikContext } from 'formik'

const TecnicalAssistance = () => {
  const { values } = useFormikContext<any>()
  return (
    <>
      {values.client && (
        <>
          <RateInput label="Urgencia" name="priority" quantity={4} />
          <LinkProject />
        </>
      )}
    </>
  )
}

export default TecnicalAssistance
