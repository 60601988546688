import React, { useState } from 'react'
import { Layout } from 'antd'
import logo from '@Assets/logo.png'
import Menu from './Menu'
import './index.less'

const { Sider } = Layout

const InnerSider = () => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    >
      <div className="sf-logo">
        <img src={logo} alt="Logo" />
      </div>
      <Menu />
    </Sider>
  )
}

export default InnerSider
