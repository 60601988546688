import React from 'react'
import { Layout, Typography, Icon } from 'antd'
import './index.less'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Layout.Footer className="sf-footer">
      <Typography>
        Copyright <Icon type="copyright" /> {` ${year} Staffing Opinno`}
      </Typography>
    </Layout.Footer>
  )
}

Footer.typeName = 'Footer'

export default Footer
