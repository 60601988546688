/* eslint-disable */
import Loading from '@Components/Loading'
import { useAlertDataQuery } from '@Generated/staffing-hooks'
import { Alert as AntdAlert, Col, Icon, Rate, Row, Typography } from 'antd'
import React from 'react'
import './index.less'

const Alert = (props: { id: string }) => {
  const { data, loading } = useAlertDataQuery({
    variables: { id: props.id },
  })
  return loading ? (
    <Loading />
  ) : (
    <AntdAlert
      message={
        <Row type="flex" justify="space-between" align="middle">
          <Col span={14}>
            <Row
              type="flex"
              justify="start"
              align="middle"
              gutter={[32, 8]}
              className="sf-alert-client-project"
            >
              <Col span={7}>
                <Typography>
                  <Icon type="safety-certificate" />
                  {data?.petition.client.name}
                </Typography>
              </Col>
              <Col>
                <Typography>
                  <Icon type="info-circle" />
                  {data?.petition?.project?.name}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row type="flex" justify="end">
              <Rate
                className="sf-alert-rate"
                disabled
                value={data?.petition.priority}
                count={4}
                character={<Icon theme="filled" type="thunderbolt" />}
              />
            </Row>
          </Col>
        </Row>
      }
      type="info"
      className="sf-alert"
    />
  )
}

export default Alert
