import React from 'react'
import Input from '../Input'
import { useFormikContext } from 'formik'
import RateInput from '../RateInput'
import LinkProject from '../LinkProject'

const ProposalAssignment = () => {
  const { values } = useFormikContext()
  return (
    <>
      {values.client && (
        <>
          <Input
            defaultValue={null}
            label="Propuesta"
            name="proposal"
            placeholder="Nombra a tu propuesta"
            required={true}
          />
          <RateInput label="Urgencia" name="priority" quantity={4} />
          <LinkProject />
        </>
      )}
    </>
  )
}

export default ProposalAssignment
