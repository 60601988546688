import { Button, Col, Icon, Row } from 'antd'
import moment, { max, min, Moment } from 'moment'
import React, { useContext, useMemo } from 'react'
import { AssignamentContext, CalendarType } from '../assignamentContext'
import './header.less'

const daysOfWeek = ['L', 'M', 'X', 'J', 'V', 'S', 'D']

const getDayOfWeekES = (date: Moment): string => {
  const currentDay = date.day() === 0 ? 6 : date.day() - 1

  return daysOfWeek[currentDay]
}

const getName = (date: Moment) =>
  date
    .format('MMMM')
    .charAt(0)
    .toUpperCase() + date.format('MMMM').slice(1)

const getMonthName = (calendarType: CalendarType, currentDate: Moment) => {
  if (calendarType === 'month') return getName(currentDate)
  // Week type
  const firstDayOfWeek = moment(currentDate).startOf('week')
  const endDayOfWeek = moment(currentDate).endOf('week')

  if (firstDayOfWeek.isSame(endDayOfWeek, 'month')) return getName(currentDate)

  return `${getName(firstDayOfWeek)}-${getName(endDayOfWeek)}`
}

type WeekMonthHeaderProps = {
  currentDate: Moment
  days: number[]
}

const MonthHeader = ({ currentDate, days }: WeekMonthHeaderProps) => (
  <>
    <Row type="flex">
      {days.map(day => (
        <Col key={`day-${day}`} className="day-header">
          {day}
        </Col>
      ))}
    </Row>
    <Row type="flex">
      {days.map(dayNumber => {
        const colMoment = moment(currentDate)
          .startOf('month')
          .add(dayNumber - 1, 'day')
        return (
          <Col key={dayNumber} className="day-week-header">
            {getDayOfWeekES(colMoment)}
          </Col>
        )
      })}
    </Row>
  </>
)

const WeekHeader = ({ currentDate, days }: WeekMonthHeaderProps) => {
  const day = moment(currentDate)
    .startOf('week')
    .subtract(1, 'day')
  return (
    <>
      <Row type="flex">
        {days.map(value => (
          <Col key={value} className="day-header">
            {day.add(1, 'day').date()}
          </Col>
        ))}
      </Row>
      <Row type="flex">
        {days.map(idx => {
          return (
            <Col key={idx} className="day-week-header">
              {daysOfWeek[idx - 1]}
            </Col>
          )
        })}
      </Row>
    </>
  )
}

const HeaderArrow = ({ iconType }: { iconType: 'left' | 'right' }) => {
  const { calendar, updateCurrentDate } = useContext(AssignamentContext)
  let canClick = false
  let onClick = () => {}
  if (iconType === 'left') {
    canClick =
      calendar.currentDate.isAfter(calendar.startDate, calendar.calendarType) &&
      calendar.currentDate.isAfter(moment(), calendar.calendarType)
    if (canClick) {
      onClick = () => {
        let newCurrentDate = max(
          calendar.currentDate.subtract(1, calendar.calendarType),
          moment(max(calendar.startDate, moment()))
        )

        if (calendar.calendarType === 'month') {
          if (newCurrentDate.isSame(moment(), 'month')) {
            newCurrentDate = moment()
          } else if (newCurrentDate.isSame(calendar.startDate, 'month'))
            newCurrentDate = calendar.startDate.clone()
          else newCurrentDate.startOf('month')
        }
        updateCurrentDate(newCurrentDate)
      }
    }
  } else if (iconType === 'right') {
    canClick = calendar.currentDate.isBefore(
      calendar.endDate,
      calendar.calendarType
    )
    if (canClick) {
      onClick = () => {
        const newCurrentDate = min(
          calendar.currentDate.add(1, calendar.calendarType),
          moment(calendar.endDate)
        )

        if (calendar.calendarType === 'month') {
          newCurrentDate.startOf('month')
        }
        updateCurrentDate(newCurrentDate)
      }
    }
  }
  return (
    <Icon
      type={iconType}
      style={canClick ? {} : { display: 'none' }}
      onClick={onClick}
    />
  )
}

const Header = () => {
  const { calendar, updateCalendarType } = useContext(AssignamentContext)

  const days = useMemo(
    () =>
      Array.from(
        {
          length:
            calendar.calendarType === 'month'
              ? calendar.currentDate.daysInMonth()
              : 7,
        },
        (_, index) => index + 1
      ),
    [calendar.calendarType, calendar.currentDate]
  )

  return (
    <>
      <Row>
        <Col span={9} className="header candidates">
          <Row>
            <Col span={8} className="label">
              Candidatos
            </Col>
            <Col span={8} className="label">
              Categoría
            </Col>
            {calendar.mode === 'editable' && (
              <Col span={8} className="label">
                Skills
              </Col>
            )}
          </Row>
        </Col>
        <Col span={15}>
          <Row className="header calendar">
            <Col span={11} offset={1} className="month-selector">
              <Row>{calendar.currentDate.year()}</Row>
              <Row>
                <Col span={1}>
                  <HeaderArrow iconType="left" />
                </Col>
                <Col span={22}>
                  {getMonthName(calendar.calendarType, calendar.currentDate)}
                </Col>
                <Col span={1}>
                  <HeaderArrow iconType="right" />
                </Col>
              </Row>
            </Col>
            <Col span={11} className="view-selector">
              <Button
                className="view-selector-button"
                onClick={updateCalendarType}
              >
                {calendar.calendarType === 'month' ? 'Mes' : 'Semana'}
              </Button>
            </Col>
          </Row>
          {calendar.calendarType === 'month' ? (
            <MonthHeader days={days} currentDate={calendar.currentDate} />
          ) : (
            <WeekHeader days={days} currentDate={calendar.currentDate} />
          )}
        </Col>
      </Row>
    </>
  )
}

export default Header
