import React from 'react'
import { Row, Typography } from 'antd'
import './description.less'

type DescriptionProps = {
  title: string
  message: string
}

const Description = (props: DescriptionProps) => {
  return (
    <>
      <Row type="flex" justify="center" style={{ marginBottom: '25px' }}>
        <Typography.Title
          style={{ fontSize: '24px', paddingTop: '25px', marginBottom: '0px' }}
        >
          {props.title}
        </Typography.Title>
      </Row>
      <Row type="flex" justify="center" style={{ marginBottom: '12px' }}>
        <div className="description">{props.message}</div>
      </Row>
    </>
  )
}

export default Description
