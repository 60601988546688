import React from 'react'
import { useSingleKnowledgeByIdQuery } from '@Generated/staffing-hooks'
import { Typography } from 'antd'

const AreaName = ({ id }: { id: string }) => {
  const { data } = useSingleKnowledgeByIdQuery({ variables: { id } })
  return <Typography>{data?.singleKnowledge.name}</Typography>
}

export default AreaName
