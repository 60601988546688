import { AssignamentContext } from '@Components/AssignmentCalendar/assignamentContext'
import { Col } from 'antd'
import moment, { Moment } from 'moment'
import React, { useContext, useState } from 'react'

const TypeDay = ({
  date,
  candidateIdx,
}: {
  date: Moment
  candidateIdx: number
}) => {
  const { calendar, addDayToCandidate, removeDayFromCandidate } = useContext(
    AssignamentContext
  )
  const [status, setStatus] = useState(
    calendar.candidates[candidateIdx].selectedDays.some(selectedDay =>
      date.isSame(selectedDay, 'day')
    )
      ? 'selected'
      : ''
  )

  if ([0, 6].includes(date.day())) {
    return <Col className="day-col disabled" />
  }

  if (
    calendar.candidates[candidateIdx].engagedDays.some(engagedDay =>
      date.isSame(engagedDay, 'day')
    ) ||
    date.isBefore(calendar.startDate, 'day') ||
    date.isAfter(calendar.endDate, 'day') ||
    date.isBefore(moment(), 'day')
  ) {
    return <Col className="day-col engaged" />
  }

  return (
    <Col
      className={`day-col ${status}`}
      onClick={() => {
        if (calendar.mode === 'editable') {
          if (status === 'selected') {
            setStatus('')
            removeDayFromCandidate(candidateIdx, moment(date))
          } else if (calendar.selectedDays !== calendar.requiredDays) {
            addDayToCandidate(candidateIdx, moment(date))
            setStatus('selected')
          }
        }
      }}
    />
  )
}

export default TypeDay
