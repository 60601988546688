import React from 'react'
import { FieldArray, useFormikContext, getIn } from 'formik'
import { Tag } from 'antd'
import uniqid from 'uniqid'
import NewSkillModal, { Skill } from './NewSkillModal'

type ExpandedRowSkillsProps = {
  skillsPath: string
  areaPath: string
}

const ExpandedRowSkills = ({
  areaPath,
  skillsPath,
}: ExpandedRowSkillsProps) => {
  const { values } = useFormikContext()
  return (
    <FieldArray name={skillsPath}>
      {helpers => {
        return (
          <>
            {getIn(values, skillsPath, []).map((e: Skill, idx: number) => {
              return (
                <Tag
                  key={uniqid()}
                  closable
                  onClose={() => {
                    helpers.remove(idx)
                  }}
                >
                  {e.node.name}
                </Tag>
              )
            })}
            <NewSkillModal
              fieldName={skillsPath}
              values={getIn(values, skillsPath, [])}
              area={getIn(values, areaPath)}
            />
          </>
        )
      }}
    </FieldArray>
  )
}

export default ExpandedRowSkills
