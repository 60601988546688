import Logo from '@Assets/logo.svg'
import Footer from '@Components/Footer'
import Loading from '@Components/Loading'
import { useAuth } from '@Hooks/auth'
import { routes } from '@Routes/routes'
import { Card, Row, Tabs, Typography } from 'antd'
import React from 'react'
import { Redirect } from 'react-router-dom'
import './index.less'
import Login from './Login'
import Register from './Singup'

const { TabPane } = Tabs

const Authenticate = () => {
  const { isLoading, isTokenValid } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  if (isTokenValid) {
    return <Redirect to={routes.base} />
  }

  return (
    <div className="authenticate-container">
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ height: '100%' }}
      >
        <Card id="sf-login-card" bordered={false}>
          <Row type="flex" justify="center">
            <Logo />
          </Row>
          <Row type="flex" justify="center">
            <Typography>
              Plataforma de gestión de equipos y proyectos
            </Typography>
          </Row>
          <Row type="flex" justify="center">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Ya tengo cuenta" key="1">
                <Login />
              </TabPane>
              <TabPane tab="Crear una cuenta" key="2">
                <Register />
              </TabPane>
            </Tabs>
          </Row>
        </Card>
      </Row>
      <Row>
        <Footer />
      </Row>
    </div>
  )
}

Authenticate.typeName = 'LoginPageView'

export default Authenticate
