import { Status, ActionType } from '@Generated/staffing-hooks'

const commonStatusColor = {
  [Status.PENDING_TO_ASSIGNAMENT]: 'blue',
  [Status.PENDING_TO_REVIEW_PROPOSAL]: 'cyan',
  [Status.APROVED]: 'green',
  [Status.CANCELED]: 'red',
}

export const statusColor = {
  ...commonStatusColor,
  [Status.DRAFT]: 'orange',
}

export const actionTypeColor = {
  ...commonStatusColor,
  [ActionType.COMMENT]: 'gray',
  [ActionType.CREATED]: 'green',
}
