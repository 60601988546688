import { useAuth } from '@Hooks/auth'
import { routes } from '@Routes/routes'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const RoleRoute = ({ component: Component, requiredRoles, ...rest }: any) => {
  const { roles } = useAuth()
  return (
    <Route
      {...rest}
      render={props =>
        requiredRoles.some((role: string) => roles.includes(role)) ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.base} />
        )
      }
    />
  )
}

export default RoleRoute
