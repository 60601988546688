import { useApolloClient } from '@apollo/react-hooks'
import {
  SingleKnowledgeFragment,
  SingleKnowledgeFragmentDoc,
  StepSummaryQuery,
} from '@Generated/staffing-hooks'
import { uniq } from 'ramda'

const GetAreas = (teams: any) => {
  const client = useApolloClient()
  const areas = uniq(
    teams.map((team: any) => {
      const data = client.readFragment<SingleKnowledgeFragment>({
        fragment: SingleKnowledgeFragmentDoc,
        id: team.area,
      })
      return data?.name
    })
  )

  return [areas.slice(0, -1).join(', '), areas.slice(-1)[0]].join(
    areas.length < 2 ? '' : ' y '
  )
}

type Team = StepSummaryQuery['petition']['intervals']['edges'][number]
export const GetAreasTeam = (teams?: Team[]) => {
  if (!teams) {
    return null
  }
  const areas = uniq(
    teams.map(({ node }) => {
      return node.knowledge.name
    })
  )

  return [areas.slice(0, -1).join(', '), areas.slice(-1)[0]].join(
    areas.length < 2 ? '' : ' y '
  )
}

export default GetAreas
