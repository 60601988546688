import { useCategoriesAndSkillsQuery } from '@Generated/staffing-hooks'
import { Button, Row, Tag } from 'antd'
import React, { useState } from 'react'
import uniqid from 'uniqid'
import Modal, { Skill, ModalData } from './Modal'

type AssignmentFilterProps = {
  knowledgeArea: {
    area: string
    areaID: string
    categories: string[]
    skills: Skill[]
  }
  onChange: (type: 'skills' | 'categories', state: ModalData) => void
  onSkillRemove: (skill: Skill) => void
  onCategoryRemove: (category: string) => void
}

const AssignmentFilter = ({
  knowledgeArea,
  onSkillRemove,
  onCategoryRemove,
  onChange,
}: AssignmentFilterProps) => {
  const { data: allData } = useCategoriesAndSkillsQuery({
    variables: { id: knowledgeArea.areaID },
  })

  const [open, toggleOpen] = useState(false)
  const [type, setType] = useState<'skills' | 'categories'>('skills')
  const [data, setData] = useState<ModalData>([])
  const [allFields, setAllFields] = useState<ModalData>([])

  const handleCancel = () => {
    toggleOpen(false)
  }

  const handleOk = (state: ModalData) => {
    onChange(type, state)
    toggleOpen(false)
  }

  return (
    <Row className="assignment-filter">
      <Row style={{ marginBottom: '10px' }}>
        {knowledgeArea.skills.map(skill => (
          <Tag key={uniqid()} closable onClose={() => onSkillRemove(skill)}>
            {skill.name}
          </Tag>
        ))}
        <Button
          type="dashed"
          icon="plus"
          style={{ height: 'auto' }}
          onClick={() => {
            setData(knowledgeArea.skills)
            setType('skills')
            if (allData) {
              setAllFields(
                allData.singleKnowledge.skills.edges.map(
                  ({ node: { id, name } }) => ({ id, name })
                )
              )
            }
            toggleOpen(true)
          }}
        >
          Añadir
        </Button>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        {knowledgeArea.categories.map(category => (
          <Tag
            key={uniqid()}
            closable
            onClose={() => onCategoryRemove(category)}
          >
            {category}
          </Tag>
        ))}
        <Button
          type="dashed"
          icon="plus"
          style={{ height: 'auto' }}
          onClick={() => {
            setData(knowledgeArea.categories)
            setType('categories')
            if (allData) {
              setAllFields(allData.specialistCategory)
            }
            toggleOpen(true)
          }}
        >
          Añadir
        </Button>
      </Row>
      <Modal
        open={open}
        data={data}
        allFields={allFields}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </Row>
  )
}

export default AssignmentFilter
