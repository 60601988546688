import { getIn, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Input, Select, InputProps, SelectProps } from 'formik-antd'

type ConditionalInputProps<T> = InputProps & {
  defaultValue: T
}

export function ConditionalInput<T>({
  defaultValue,
  name,
  ...props
}: ConditionalInputProps<T>) {
  const { values, setFieldValue } = useFormikContext<any>()
  useEffect(() => {
    if (getIn(values, name) === undefined) {
      setFieldValue(name, defaultValue)
    }
  })
  const value = getIn(values, name)
  if (value === undefined) {
    return null
  }
  return <Input name={name} {...props} />
}

type ConditionalSelectProps<T> = SelectProps & {
  defaultValue: T
}

export function ConditionalSelect<T>({
  defaultValue,
  name,
  ...props
}: ConditionalSelectProps<T>) {
  const { values, setFieldValue } = useFormikContext<any>()
  useEffect(() => {
    if (getIn(values, name) === undefined) {
      setFieldValue(name, defaultValue)
    }
  })
  const value = getIn(values, name)
  if (value === undefined) {
    return null
  }
  return (
    <Select
      style={{ width: 342 }}
      value={!value ? undefined : value}
      name={name}
      {...props}
    />
  )
}
