import { Divider, Layout as AntdLayout } from 'antd'
import React, { ReactChild } from 'react'
import Breadcrumb from './Breadcrumb'
import Header from './Header'
import './index.less'
import Sider from './Sider'

type LayoutProps = {
  children: ReactChild
}

const { Content } = AntdLayout

const Layout = ({ children }: LayoutProps) => (
  <AntdLayout className="sf-layout">
    <Sider />
    <AntdLayout>
      <Header />
      <Content className="sf-layout-content">
        <Breadcrumb />
        <Divider className="sf-layout-divider" />
        <div className="sf-layout-content-children">{children}</div>
      </Content>
    </AntdLayout>
  </AntdLayout>
)

export default Layout
