import { Row, Table } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Description from '@Components/Description'
import { usePetitionsQuery } from '@Generated/staffing-hooks'
import { routes } from '@Routes/routes'
import { columns } from './columns'

const Details = () => {
  const history = useHistory()
  const { data, loading } = usePetitionsQuery({
    fetchPolicy: 'no-cache',
  })

  return (
    <>
      <Description
        title="Consultar peticiones de Tickets"
        message="Aquí puedes consultar los tickets de petición que hayas realizado al gestor, comprobar su estado y ver el detalle de cada uno"
      />
      <Row type="flex" justify="center">
        <Table
          onRow={({ node }) => ({
            onDoubleClick: () => {
              if (node.status === 'DRAFT') {
                history.push(`/petitions/new/${node.type.code.toLowerCase()}`, {
                  id: node.id,
                  type: node.type.code,
                })
              } else {
                history.push(`${routes.petitionsList}/${node.id}`, {
                  id: node.id,
                })
              }
            },
          })}
          pagination={{ pageSize: 8 }}
          loading={loading}
          columns={columns}
          dataSource={data?.petitions.edges}
          style={{ width: '90.5%' }}
          rowKey={record => record.node.id}
        />
      </Row>
    </>
  )
}

export default Details
