/* THIS IS A GENERATED FILE - DO NOT MODIFY */
/* eslint-disable */



      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Node",
        "possibleTypes": [
          {
            "name": "Tag"
          },
          {
            "name": "Project"
          },
          {
            "name": "Client"
          },
          {
            "name": "Petition"
          },
          {
            "name": "PetitionType"
          },
          {
            "name": "User"
          },
          {
            "name": "Role"
          },
          {
            "name": "Permission"
          },
          {
            "name": "Skill"
          },
          {
            "name": "Interval"
          },
          {
            "name": "Knowledge"
          },
          {
            "name": "Proposal"
          },
          {
            "name": "Assignament"
          }
        ]
      }
    ]
  }
};
      export default result;
    