import React from 'react'
import { Steps, Row, Col } from 'antd'
import { ManagePetitionQuery } from '@Generated/staffing-hooks'
import uniqid from 'uniqid'

const { Step } = Steps

export type KnowledgeAreasType = ManagePetitionQuery['petition']['intervals']['edges'][number]

type AssignamentKnowledgeProps = {
  activeArea: number
  onChage: (index: number) => void
  knowledgeAreas?: KnowledgeAreasType[]
}

const AssignamentKnowledge = ({
  knowledgeAreas,
  activeArea,
  onChage,
}: AssignamentKnowledgeProps) => {
  return (
    <Row type="flex">
      <Col>
        <Steps
          progressDot
          direction="vertical"
          size="small"
          current={activeArea}
          onChange={onChage}
        >
          {knowledgeAreas &&
            knowledgeAreas.map(({ node }) => (
              <Step
                key={uniqid()}
                title={`Área de ${node.knowledge.name
                  .charAt(0)
                  .toUpperCase()}${node.knowledge.name.substring(1)}`}
              />
            ))}
        </Steps>
      </Col>
    </Row>
  )
}

export default AssignamentKnowledge
