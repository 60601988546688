import { Skill } from '@Components/Petitions/New/Types/ProjectAssignament/Configuration/NewSkillModal'
import {
  Category,
  useCreateProjectAssignmentMutation,
} from '@Generated/staffing-hooks'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

type Team = {
  key: string
  area: string
  specialist: Category
  days: string
  assignamentStart: string
  skills: Skill[]
}

export type ProjectValues = {
  configuration: {
    endDate: string
    team: Team[]
  }
  documentation: {
    comment: string
    files: [{}]
  }
  resumen: {}
}

export const usePetitionMutation = (draft: string) => {
  const [
    createProjectAssignamentMutation,
  ] = useCreateProjectAssignmentMutation()

  const history = useHistory()

  const createProjectAssignament = useCallback(
    (route: string) => async ({
      configuration,
      documentation,
    }: ProjectValues) => {
      const { data } = await createProjectAssignamentMutation({
        variables: {
          draft,
          input: {
            deadLine: configuration.endDate,
            comment: documentation.comment,
            intervals: configuration.team.map(row => ({
              start: row.assignamentStart,
              end: configuration.endDate,
              duration: +row.days,
              min: 1,
              max: 1,
              knowledge: row.area,
              category: row.specialist,
              skills: row.skills.map(({ node }) => node.id),
            })),
            documents: documentation.files.map(
              ({ fileName }: any): string => fileName
            ),
          },
        },
      })
      if (data) {
        history.push(route)
      }
    },
    [createProjectAssignamentMutation, draft, history]
  )
  return { createProjectAssignament }
}
