import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { ActionType } from '@Generated/staffing-hooks'
import { actionTypeColor } from '@Utils/enums/colors'
import { Badge } from 'antd'
import { actionTypeValues } from '@Utils/enums/mapping-enums'
import moment from 'moment'

export const columns = [
  {
    title: 'Área de conocimiento',
    dataIndex: 'knowledge',
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
  },
  {
    title: 'Rango',
    dataIndex: 'category',
    width: '100px',
  },
  {
    title: 'Mín-Máx',
    dataIndex: 'experts',
    width: '100px',
  },
  {
    title: 'Días',
    dataIndex: 'days',
    width: '70px',
  },
  {
    title: 'Inicio Asignación',
    dataIndex: 'assignamentStart',
    width: '150px',
  },
]

export const commentColumns: ColumnProps<any>[] = [
  {
    dataIndex: 'type',
    width: '200px',
    ellipsis: true,
    render: (record: ActionType) => (
      <Badge color={actionTypeColor[record]} text={actionTypeValues[record]} />
    ),
  },
  {
    dataIndex: 'name',
    width: '150px',
    ellipsis: true,
  },
  {
    dataIndex: 'comment',
    ellipsis: true,
  },
  {
    dataIndex: 'date',
    width: '150px',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => (moment(a.date).isAfter(b.date) ? 1 : -1),
  },
]
