import { Icon, Row } from 'antd'
import React, { useContext } from 'react'
import { AssignamentContext } from '../assignamentContext'
import './footer.less'

const Footer = () => {
  const { calendar } = useContext(AssignamentContext)
  return (
    <Row className="footer" type="flex">
      {calendar.mode === 'editable' && (
        <div>
          <div>
            <Icon type="calendar" className="calendar-icon" />
          </div>
          <div>
            Días requeridos:
            <span className="label-value">{calendar.requiredDays}</span>
          </div>
          <div>
            Días seleccionados:
            <span
              className={`label-value ${
                calendar.selectedDays === calendar.requiredDays
                  ? 'valid'
                  : 'invalid'
              }`}
            >
              {calendar.selectedDays}
            </span>
          </div>
        </div>
      )}
      <div>
        <div>
          Inicio:
          <span className="label-value">
            {calendar.startDate.format('DD/MM/YYYY')}
          </span>
        </div>
        <div>
          Finalización límite:
          <span className="label-value">
            {calendar.endDate.format('DD/MM/YYYY')}
          </span>
        </div>
      </div>
    </Row>
  )
}

export default Footer
