import { PetitionsQuery, Status } from '@Generated/staffing-hooks'
import { statusColor } from '@Utils/enums/colors'
import { statusValues } from '@Utils/enums/mapping-enums'
import { Badge } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'

type PetitionsColumn = PetitionsQuery['petitions']['edges'][number]

const renderStatus = (text: Status) => {
  return <Badge color={statusColor[text]} text={statusValues[text]} />
}

const renderDate = (record: string) => {
  const date = moment(record)
  return date.isValid() ? date.format('DD/MM/YYYY') : '-'
}

export const columns: ColumnProps<PetitionsColumn>[] = [
  {
    title: 'Ticket',
    dataIndex: 'node.code',
    align: 'center',
    render: record => record || '-',
  },
  {
    title: 'Proyecto',
    dataIndex: 'node.project.name',
  },
  {
    title: 'Estado',
    dataIndex: 'node.status',
    filters: Object.entries(statusValues).map(([value, text]) => ({
      text,
      value,
    })),
    filterMultiple: true,
    onFilter: (value: any, record) => record.node.status.indexOf(value) === 0,
    sorter: (a, b) => a.node.status.localeCompare(b.node.status),
    sortDirections: ['ascend', 'descend'],
    render: renderStatus,
  },
  {
    title: 'Fecha de petición',
    dataIndex: 'node.createdAt',
    render: a => moment(a).format('DD/MM/YYYY'),
    sorter: (a, b) => a.node.createdAt.localeCompare(b.node.createdAt),
    sortDirections: ['ascend', 'descend'],
    align: 'center',
  },
  {
    title: 'Comienzo estimado',
    dataIndex: 'node.start',
    align: 'center',
    render: renderDate,
  },
  {
    title: 'Finalización estimada',
    dataIndex: 'node.end',
    align: 'center',
    render: renderDate,
  },
  {
    title: 'Especialistas',
    dataIndex: 'node.experts',
    align: 'center',
    render: experts => {
      if (!experts) return '-'
      if (experts.min === experts.max) return experts.min
      return `${experts.min} - ${experts.max}`
    },
  },
]
