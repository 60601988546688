import { Checkbox, Modal as AntdModal, Row } from 'antd'
import { reject, equals } from 'ramda'
import React, { useEffect, useState } from 'react'
import uniqid from 'uniqid'

export type Skill = {
  id: string
  name: string
}

export type ModalData = (Skill | string)[]

type ModalProps = {
  open: boolean
  data: ModalData
  allFields: ModalData
  handleCancel: () => void
  handleOk: (state: ModalData) => void
}

const Modal = ({
  open,
  data,
  allFields,
  handleCancel,
  handleOk,
}: ModalProps) => {
  const [state, setState] = useState<ModalData>([])

  useEffect(() => {
    setState(data)
  }, [data])

  return (
    <AntdModal
      closable={false}
      visible={open}
      onCancel={handleCancel}
      onOk={() => handleOk(state)}
    >
      {allFields.map(element => (
        <Row key={uniqid()}>
          <Checkbox
            key={uniqid()}
            value={element}
            checked={state.some(equals(element))}
            onChange={({ target }) =>
              target.checked
                ? setState([...state, element])
                : setState(reject(equals(element), state))
            }
          >
            {typeof element === 'string' ? element : element.name}
          </Checkbox>
        </Row>
      ))}
    </AntdModal>
  )
}

export default Modal
