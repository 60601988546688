import { Row, Col, Typography, Icon } from 'antd'
import React from 'react'
import moment from 'moment'
import './index.less'

type StepSummaryProps = {
  endDate: string | null
  teamSize: string | number | undefined
  knowledgeAreas: string | null
  link: string | undefined
}

const StepSummary = ({
  endDate,
  teamSize,
  knowledgeAreas,
  link,
}: StepSummaryProps) => {
  return (
    <Row type="flex" justify="space-between" className="sf-step-summary">
      <Col>
        <Typography>
          <Icon type="calendar" />
          {`Fin del proyecto ${endDate &&
            moment(endDate).format('DD/MM/YYYY')}`}
        </Typography>
      </Col>
      <Col>
        <Typography>
          <Icon type="team" />
          Equipo minimo de {teamSize} especialista
          {teamSize && (teamSize > 1 ? 's' : null)}
        </Typography>
      </Col>
      <Col>
        <Typography>
          <Icon type="book" /> Áreas de {knowledgeAreas}
        </Typography>
      </Col>
      <Col>
        <Typography>
          <Icon type="link" />
          {link}
        </Typography>
      </Col>
    </Row>
  )
}
export default StepSummary
