import { useApolloClient } from '@apollo/react-hooks'
import { SkillsFragment, SkillsFragmentDoc } from '@Generated/staffing-hooks'
import { Button, Checkbox, Input, Modal, Row } from 'antd'
import { useFormikContext } from 'formik'
import reject from 'ramda/es/reject'
import React, { useCallback, useState, useEffect } from 'react'
import { stringSimilarity } from 'string-similarity-js'

export type Skill = SkillsFragment['skills']['edges'][number] & { coef: number }

type NewSkillModalProps = {
  values: Skill[]
  area: string
  fieldName: string
}

const NewSkillModal = ({ values, area, fieldName }: NewSkillModalProps) => {
  const { setFieldValue } = useFormikContext<any>()
  const [skills, setSkills] = useState<Skill[]>(values)
  const [open, setOpen] = useState(false)
  const [term, setTerm] = useState('')
  const client = useApolloClient()

  const data = client.readFragment<SkillsFragment>({
    id: area,
    fragment: SkillsFragmentDoc,
  })
  useEffect(() => {
    setSkills([])
  }, [values])

  useEffect(() => {
    setFieldValue(fieldName, [])
  }, [area, fieldName, setFieldValue])

  const handleOk = useCallback(() => {
    setOpen(false)
    setFieldValue(fieldName, skills)
  }, [fieldName, setFieldValue, skills])

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <Button
        type="dashed"
        className="sf-add-specialist"
        onClick={() => {
          setSkills(values)
          setOpen(true)
        }}
        disabled={data?.__typename !== 'Knowledge'}
      >
        Añadir Skill
      </Button>
      {data?.__typename === 'Knowledge' && (
        <Modal
          centered
          closable={false}
          visible={open}
          title={
            <Input.Search
              placeholder="Buscar skill"
              onChange={e => setTerm(e.target.value)}
            />
          }
          footer={
            <Row type="flex" justify="center">
              <Button
                key="back"
                type="link"
                style={{
                  color: '#868686',
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button type="link" key="submit" onClick={handleOk}>
                Añadir Skill
              </Button>
            </Row>
          }
        >
          {data.skills.edges
            .map(({ node }) => ({
              node,
              coef: stringSimilarity(node.name, term, 2),
            }))
            .sort((e1, e2) => e2.coef - e1.coef)
            .map(edge => (
              <Row key={edge.node.id}>
                <Checkbox
                  key={edge.node.id}
                  value={edge}
                  checked={skills.some(skill => skill.node.id === edge.node.id)}
                  onChange={({ target }) =>
                    target.checked
                      ? setSkills([...skills, edge])
                      : setSkills(
                          reject<Skill>(e => e.node.id === edge.node.id, skills)
                        )
                  }
                >
                  {edge.node.name}
                </Checkbox>
              </Row>
            ))}
        </Modal>
      )}
    </>
  )
}

export default NewSkillModal
