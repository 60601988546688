import { FormikWizardStepType } from 'formik-wizard/dist/types'
import * as Yup from 'yup'
import Configuration from './Configuration'
import Documentation from './Documentation'
import Resumen from './Resumen'

const DocumentationSchema = Yup.object().shape({
  comment: Yup.string().required('Campo obligatorio'),
  files: Yup.array(),
})

const ConfigurationSchema = Yup.object().shape({
  selectedDays: Yup.number(),
  requiredDays: Yup.number().oneOf([Yup.ref('selectedDays')]),
})

const steps: FormikWizardStepType[] = [
  {
    id: 'configuration',
    component: Configuration,
    initialValues: {
      proposal: [],
      selectedDays: 0,
      requiredDays: 1,
    },
    validationSchema: ConfigurationSchema,
  },
  {
    id: 'documentation',
    component: Documentation,
    initialValues: {
      comment: '',
      files: [],
    },
    validationSchema: DocumentationSchema,
  },
  {
    id: 'resumen',
    component: Resumen,
    initialValues: {},
  },
]

export default steps
