import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Row, Col, Alert } from 'antd'
import RateInput from '../RateInput'
import TicketToExtend from './TicketToExtend'
import './index.less'

const TicketExtension = () => {
  const { values, setFieldValue } = useFormikContext<any>()

  useEffect(() => {
    if (values.linked === undefined) {
      setFieldValue('linked', {})
    }
  })

  if (values.linked === undefined) {
    return null
  }

  return (
    <>
      <RateInput label="Urgencia" name="priority" quantity={4} />
      <TicketToExtend />
      {Object.entries(values.linked).length > 0 && (
        <Row type="flex" justify="start">
          <Col offset={8}>
            <Alert
              message={`Proyecto / ${values.linked.node.name}`}
              className="sf-info"
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default TicketExtension
