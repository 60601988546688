import React from 'react'
import { Row, Icon, Divider } from 'antd'
import { ArrayHelpers } from 'formik'
import uniqid from 'uniqid'
import './index.less'

type MenuProps<T> = {
  index: number
  helpers: ArrayHelpers
  record: T
}

export default function Menu<T>({ helpers, index, record }: MenuProps<T>) {
  return (
    <div style={{ width: '144px' }}>
      <Row onClick={() => helpers.remove(index)} className="sf-hover-cursor">
        <Icon type="close" style={{ color: '#00adb5', marginRight: '15px' }} />
        Eliminar
      </Row>
      <Divider style={{ margin: '8px', width: '100%' }} />
      <Row
        onClick={() => helpers.push({ ...record, key: uniqid() })}
        className="sf-hover-cursor"
      >
        <Icon type="plus" style={{ color: '#00adb5', marginRight: '15px' }} />
        Duplicar
      </Row>
    </div>
  )
}
