/* eslint-disable @typescript-eslint/no-non-null-assertion */
import AssignmentCalendar from '@Components/AssignmentCalendar'
import { CandidateData } from '@Components/AssignmentCalendar/types'
import { useManagePetitionQuery } from '@Generated/staffing-hooks'
import { Row } from 'antd'
import { useFormikWizard } from 'formik-wizard'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router-dom'

export type ManagementFormikWizardContext = {
  configuration: {
    proposal: CandidateData[][]
  }
  documentation: {
    comment: string
    files: string[]
  }
  resumen: {}
}

const Summary = () => {
  const { values } = useFormikWizard<ManagementFormikWizardContext>()
  const { id } = useParams<{ id: string }>()
  const { data } = useManagePetitionQuery({
    fetchPolicy: 'cache-only',
    variables: { id },
  })

  return (
    <Row style={{ marginTop: '25px' }}>
      <AssignmentCalendar
        mode="view"
        candidates={values.configuration.proposal
          .flat()
          .filter(candidate => candidate.selectedDays.length > 0)}
        startDate={moment(data!.petition!.start as string)}
        endDate={moment(data!.petition!.end as string)}
      />
    </Row>
  )
}

export default Summary
