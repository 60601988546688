import React, { useContext } from 'react'
import { AssignamentContext } from '../assignamentContext'
import Candidate from './Candidate'

const CandidateList = () => {
  const { calendar } = useContext(AssignamentContext)
  return (
    <>
      {calendar.candidates.map((candidate, idx) => (
        <Candidate key={candidate.id} candidateIdx={idx} />
      ))}
    </>
  )
}

export default CandidateList
