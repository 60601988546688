import * as R from 'ramda'

const fmerge = R.flip(R.merge)
const isEmptyOrNull = R.either(R.isEmpty, R.isNil)
const notEmpty = R.complement(isEmptyOrNull)
const safeHead = R.when(notEmpty, val => R.head(R.values(val)))
// findByField :: (A, )
const findInState = (field: object, newState: object, state: any[]) =>
  R.pipe(
    R.findIndex(R.pathEq(R.keys(field), safeHead(field))),
    R.cond([
      [
        R.gt(R.__, -1),
        idx => R.over(R.lensIndex(idx), fmerge(newState), state),
      ],
      [R.T, R.always(state)],
    ])
  )(state)

export const findByField: <T>(
  path: Partial<T>,
  newState: Partial<T>
) => (state: T[]) => T[] = R.curry(findInState) as any
