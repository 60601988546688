import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  ticketType: Yup.string().required('Campo obligatorio'),
  client: Yup.string()
    .required('Campo obligatorio')
    .nullable(),
  project: Yup.string()
    .trim()
    .when('ticketType', {
      is: ticketType => ticketType === 'ASIGNPY',
      then: Yup.string().required('Campo obligatorio'),
    }),
  proposal: Yup.string().when('ticketType', {
    is: ticketType => ticketType === 'ASIGNPR',
    then: Yup.string().required('Campo obligatorio'),
  }),
  priority: Yup.number()
    .min(0)
    .max(4),
  link: Yup.boolean().when('ticketType', {
    is: ticketType => ticketType !== 'AMP',
    then: Yup.boolean(),
  }),
  linked: Yup.object().when('ticketType', {
    is: ticketType => ticketType === 'AMP',
    then: Yup.object().shape({
      node: Yup.object().shape({
        name: Yup.string().required('Campo obligatorio'),
      }),
    }),
  }),
  related: Yup.array().when(['ticketType', 'link'], {
    is: (ticketType, link) => ticketType !== 'AMP' && link,
    then: Yup.array().min(1, 'Selecciona al menos un vínculo'),
  }),
})
