import { resolvers } from '@Graphql/client/resolvers'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import introspectionResult from '@Generated/fragment-matcher'
import { authLink } from './auth'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
})

const link = ApolloLink.from([
  authLink,
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${JSON.stringify(
            message
          )}, Location: ${locations}, Path: ${path}`
        )
      )
    }
    if (networkError) console.warn(`[Network error]: ${networkError}`)
  }),
  createUploadLink({
    uri:
      process.env.NODE_ENV === 'production'
        ? 'https://agile-staffing.opinno.io/graphql'
        : 'http://localhost:4000/graphql',
  }),
])

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: object => object.id,
})

const data = {
  isLoggedIn: false,
  currentLocalPetition: null,
}

cache.writeData({ data })

const client = new ApolloClient({
  link,
  cache,
  resolvers: resolvers as any,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
})

client.onResetStore(async () => cache.writeData({ data }))

export default client
