import { useAuth } from '@Hooks/auth'
import { routes } from '@Routes/routes'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Loading from '../Loading'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isTokenValid, isLoading } = useAuth()
  if (isLoading) {
    return <Loading />
  }
  return (
    <Route
      {...rest}
      render={props =>
        isTokenValid ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.authenticate} />
        )
      }
    />
  )
}

export default PrivateRoute
