import { useLogInMutation } from '@Generated/staffing-hooks'
import { useAuth } from '@Hooks/auth'
import { routes } from '@Routes/routes'
import { Button, Row } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Input } from 'formik-antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import './index.less'

const initialValues = {
  email: '',
  password: '',
}

export type InitialValues = typeof initialValues

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Campo obligatorio'),
  password: Yup.string()
    .label('Password')
    .required('Campo obligatorio'),
})

const Login = () => {
  const history = useHistory()
  const { signIn } = useAuth()
  const [login] = useLogInMutation({ fetchPolicy: 'no-cache' })

  const handleSubmit = async (
    input: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    const { data: payload, errors } = await login({ variables: { input } })
    if (errors) {
      const message = 'Email o Contraseña Incorrectos'
      actions.setErrors({ email: message, password: message })
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      signIn(payload!.login.accessToken)
      history.replace(routes.base)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <Form id="sf-login-form">
          <Form.Item name="email" hasFeedback>
            <Input
              name="email"
              className="input-size"
              autoComplete="username"
              placeholder="Usuario"
            />
          </Form.Item>
          <Form.Item name="password" hasFeedback>
            <Input.Password
              name="password"
              autoComplete="current-password"
              placeholder="Contraseña"
            />
          </Form.Item>
          <Row type="flex" justify="end">
            <Link to={routes.forgotPassword}>He olvidado mi contraseña</Link>
          </Row>
          <Form.Item name="submit">
            <Button htmlType="submit" type="primary">
              Iniciar Sesión
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

Login.typeName = 'LoginForm'

export default Login
