import Assignment from '@Components/Assignment'
import Loading from '@Components/Loading'
import { useManagePetitionQuery } from '@Generated/staffing-hooks'
import React from 'react'
import { useParams } from 'react-router-dom'

const Configuration = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useManagePetitionQuery({
    variables: { id },
  })

  if (loading) {
    return <Loading />
  }

  if (data) {
    return <Assignment data={data} />
  }
  return <>Error</>
}

export default Configuration
