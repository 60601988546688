import React from 'react'
import { Form } from 'formik-antd'
import { ConditionalInput } from '@Components/ConditionalField'

const { Item } = Form

type InputProps<T> = {
  required: boolean
  label: string
  name: string
  placeholder: string
  defaultValue: T
}

export default function Input<T>({
  name,
  label,
  required,
  placeholder,
}: InputProps<T>) {
  return (
    <Item name={name} label={label} required={required}>
      <ConditionalInput
        defaultValue=""
        required={required}
        name={name}
        placeholder={placeholder}
        style={{ width: 342 }}
      />
    </Item>
  )
}
