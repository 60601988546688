export const routes = {
  base: '/',
  petitionsList: '/petitions',
  petitionAccept: '/petitions/accept-decline',
  petitionDetails: '/petitions/:id',
  petitionsNew: '/petitions/new',
  petitionStepper: '/petitions/new/:code',
  petitionManage: '/petitions/manage/:id',
  petitionManageBase: '/petitions/manage',
  authenticate: '/authenticate',
  forgotPassword: '/passwordrecovery',
}
