import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Row, Button, Typography, Divider, Col, Checkbox } from 'antd'
import { useFormikContext, getIn } from 'formik'
import {
  useClientPetitionsLazyQuery,
  ClientPetitionsQuery,
} from '@Generated/staffing-hooks'
import './index.less'
import reject from 'ramda/es/reject'

export type RelatedPetition = ClientPetitionsQuery['client']['petitions']['edges'][0]

type RelatedModalProps = {
  fieldName: string
  client: string
}

const RelatedModal = ({ fieldName, client }: RelatedModalProps) => {
  const { setFieldValue, values } = useFormikContext<any>()
  const [modal, setModal] = useState(false)
  const [related, setRelated] = useState<RelatedPetition[]>([])
  useEffect(() => {
    setRelated(getIn(values, fieldName, []))
  }, [client, fieldName, values])
  const [getProjects, { data }] = useClientPetitionsLazyQuery()
  const handleOk = useCallback(() => {
    setModal(false)
    setFieldValue(fieldName, related)
  }, [fieldName, related, setFieldValue])

  const handleCancel = useCallback(() => {
    setModal(false)
  }, [])

  return (
    <>
      <Row type="flex" justify="start">
        <Col offset={8}>
          <Button
            type="link"
            onClick={() => {
              const variables = {
                id: client,
              }
              getProjects({ variables })
              setModal(true)
            }}
            style={{ padding: 0 }}
          >
            Seleccionar vínculos
          </Button>
        </Col>
      </Row>
      <Modal
        title={
          <Row type="flex" justify="center" className="sf-title-row">
            {/* eslint-disable-next-line no-restricted-globals */}
            {data?.client.name}
          </Row>
        }
        visible={modal}
        centered
        closable={false}
        footer={
          <Row type="flex" justify="center">
            <Button
              key="back"
              type="link"
              className="sf-on-cancel"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button type="link" key="submit" onClick={handleOk}>
              Añadir proyecto
            </Button>
          </Row>
        }
      >
        {data?.client.petitions.edges.map((element, idk, array) => {
          return (
            <div key={idk}>
              <Row type="flex" justify="space-between">
                <Checkbox
                  value={element}
                  key={element.node.id}
                  checked={related.some(
                    r => r.node?.project?.name === element.node?.project?.name
                  )}
                  onChange={({ target }) =>
                    target.checked
                      ? setRelated(r => [...r, element])
                      : setRelated(r =>
                          reject<RelatedPetition>(
                            e =>
                              e.node?.project?.name ===
                              element.node?.project?.name,
                            r
                          )
                        )
                  }
                >
                  {element.node?.project?.name}
                </Checkbox>
                <Typography.Text>{element.node.type.name}</Typography.Text>
              </Row>
              {idk !== array.length - 1 && <Divider />}
            </div>
          )
        })}
      </Modal>
    </>
  )
}

export default RelatedModal
