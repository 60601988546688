import React, { useEffect } from 'react'
import { useFormikContext, FieldArray } from 'formik'
import { Form, Radio } from 'formik-antd'
import { Row, Col, Alert } from 'antd'
import RelatedModal, { RelatedPetition } from './RelatedModal'
import './index.less'

const { Item } = Form

const LinkProject = () => {
  const { values, setFieldValue } = useFormikContext<any>()
  useEffect(() => {
    setFieldValue('related', [])
  }, [values.link, setFieldValue])
  
  return (
    <>
      <Item style={{ margin: 0 }} label="Vinculación" name="link">
        <Radio.Group name="link">
          <Radio name="link" value>
            Sí
          </Radio>
          <Radio name="link" value={false}>
            No
          </Radio>
        </Radio.Group>
      </Item>
      {values.link && (
        <FieldArray name="related">
          {() => {
            return (
              <>
                {values.related.map((e: RelatedPetition) => (
                  <Row key={e.node.id} type="flex" justify="start">
                    <Col offset={8}>
                      <Alert
                        message={`${e.node.type.name} / ${e.node?.project?.name}`}
                        className="sf-info"
                      />
                    </Col>
                  </Row>
                ))}
                <RelatedModal fieldName="related" client={values.client} />
              </>
            )
          }}
        </FieldArray>
      )}
    </>
  )
}

export default LinkProject
