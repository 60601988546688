/* THIS IS A GENERATED FILE - DO NOT MODIFY */
/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** RelayId custom scalar type */
  RelayID: string,
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};

export type Action = {
   __typename?: 'Action',
  createdAt: Scalars['DateTime'],
  type: ActionType,
  comment?: Maybe<Scalars['String']>,
  user: User,
};

export type ActionInput = {
  comment?: Maybe<Scalars['String']>,
  type: ActionType,
};

/** Timeline Actions */
export enum ActionType {
  PENDING_TO_ASSIGNAMENT = 'PENDING_TO_ASSIGNAMENT',
  PENDING_TO_REVIEW_PROPOSAL = 'PENDING_TO_REVIEW_PROPOSAL',
  CANCELED = 'CANCELED',
  APROVED = 'APROVED',
  CREATED = 'CREATED',
  COMMENT = 'COMMENT'
}

export type AggregateAssignament = {
   __typename?: 'AggregateAssignament',
  count: Scalars['Int'],
};

export type AggregateClient = {
   __typename?: 'AggregateClient',
  count: Scalars['Int'],
};

export type AggregateInterval = {
   __typename?: 'AggregateInterval',
  count: Scalars['Int'],
};

export type AggregateKnowledge = {
   __typename?: 'AggregateKnowledge',
  count: Scalars['Int'],
};

export type AggregatePermission = {
   __typename?: 'AggregatePermission',
  count: Scalars['Int'],
};

export type AggregatePetition = {
   __typename?: 'AggregatePetition',
  count: Scalars['Int'],
};

export type AggregatePetitionType = {
   __typename?: 'AggregatePetitionType',
  count: Scalars['Int'],
};

export type AggregateProject = {
   __typename?: 'AggregateProject',
  count: Scalars['Int'],
};

export type AggregateProposal = {
   __typename?: 'AggregateProposal',
  count: Scalars['Int'],
};

export type AggregateRole = {
   __typename?: 'AggregateRole',
  count: Scalars['Int'],
};

export type AggregateSkill = {
   __typename?: 'AggregateSkill',
  count: Scalars['Int'],
};

export type AggregateTag = {
   __typename?: 'AggregateTag',
  count: Scalars['Int'],
};

export type AggregateUser = {
   __typename?: 'AggregateUser',
  count: Scalars['Int'],
};

export type Assignament = Node & {
   __typename?: 'Assignament',
  id: Scalars['ID'],
  /** User assigned to a project */
  expert: User,
  /** Expert availability in this project */
  percentage: Scalars['Float'],
  days: Array<Scalars['DateTime']>,
  proposal: Proposal,
};

export type AssignamentConnection = {
   __typename?: 'AssignamentConnection',
  pageInfo: PageInfo,
  edges: Array<AssignamentEdge>,
  aggregate: AggregateAssignament,
};

export type AssignamentEdge = {
   __typename?: 'AssignamentEdge',
  node: Assignament,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type AssignamentFilter = {
  /** Expert availability in this project */
  percentage?: Maybe<FloatFilter>,
  and?: Maybe<Array<AssignamentFilter>>,
  or?: Maybe<Array<AssignamentFilter>>,
};

export type AssignamentInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  /** User assigned to a project */
  expert: Scalars['RelayID'],
  /** Expert availability in this project */
  percentage?: Maybe<Scalars['Float']>,
  days: Array<Scalars['DateTime']>,
};

/** Category of Specialist */
export enum Category {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4'
}

export type Client = Node & {
   __typename?: 'Client',
  id: Scalars['ID'],
  name: Scalars['String'],
  tags: Array<TagConnection>,
  projects: ProjectConnection,
  petitions: PetitionConnection,
};


export type ClientTagsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<TagFilter>
};


export type ClientProjectsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ProjectFilter>
};


export type ClientPetitionsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PetitionFilter>
};

export type ClientConnection = {
   __typename?: 'ClientConnection',
  pageInfo: PageInfo,
  edges: Array<ClientEdge>,
  aggregate: AggregateClient,
};

export type ClientEdge = {
   __typename?: 'ClientEdge',
  node: Client,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type ClientFilter = {
  name?: Maybe<StringFilter>,
  and?: Maybe<Array<ClientFilter>>,
  or?: Maybe<Array<ClientFilter>>,
};

export type ClientInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  tags: Array<Scalars['RelayID']>,
};

export type CreateAssignamentPayload = {
   __typename?: 'CreateAssignamentPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  assignament: Assignament,
};

export type CreateClientPayload = {
   __typename?: 'CreateClientPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  client: Client,
};

export type CreateIntervalPayload = {
   __typename?: 'CreateIntervalPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  interval: Interval,
};

export type CreateKnowledgePayload = {
   __typename?: 'CreateKnowledgePayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  knowledge: Knowledge,
};

export type CreatePermissionPayload = {
   __typename?: 'CreatePermissionPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  permission: Permission,
};

export type CreatePetitionPayload = {
   __typename?: 'CreatePetitionPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  petition: Petition,
};

export type CreatePetitionTypePayload = {
   __typename?: 'CreatePetitionTypePayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  petitionType: PetitionType,
};

export type CreateProjectPayload = {
   __typename?: 'CreateProjectPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  project: Project,
};

export type CreateProposalPayload = {
   __typename?: 'CreateProposalPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  proposal: Proposal,
};

export type CreateRolePayload = {
   __typename?: 'CreateRolePayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  role: Role,
};

export type CreateSkillPayload = {
   __typename?: 'CreateSkillPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  skill: Skill,
};

export type CreateTagPayload = {
   __typename?: 'CreateTagPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  tag: Tag,
};

export type CreateUserPayload = {
   __typename?: 'CreateUserPayload',
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  user: User,
};

export type DateFilter = {
  /** Matches any of the values specified in an array. */
  in?: Maybe<Array<Scalars['DateTime']>>,
  /** Matches none of the values specified in an array. */
  nin?: Maybe<Array<Scalars['DateTime']>>,
  /** Matches values that are equal to a specified value. */
  eq?: Maybe<Scalars['DateTime']>,
  /** Matches values that are greater than a specified value. */
  gt?: Maybe<Scalars['DateTime']>,
  /** Matches values that are greater than or equal to a specified value. */
  gte?: Maybe<Scalars['DateTime']>,
  /** Matches values that are less than a specified value. */
  lt?: Maybe<Scalars['DateTime']>,
  /** Matches values that are less than or equal to a specified value. */
  lte?: Maybe<Scalars['DateTime']>,
  /** Matches all values that are not equal to a specified value. */
  ne?: Maybe<Scalars['DateTime']>,
};


export type Duration = {
   __typename?: 'Duration',
  min: Scalars['Int'],
  max: Scalars['Int'],
};

export type DurationInput = {
  min: Scalars['Int'],
  max: Scalars['Int'],
};

export type Experts = {
   __typename?: 'Experts',
  min: Scalars['Int'],
  max: Scalars['Int'],
};

/** All possible factors of petition */
export enum Factors {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}

export type FloatFilter = {
  /** Matches any of the values specified in an array. */
  in?: Maybe<Array<Scalars['Float']>>,
  /** Matches none of the values specified in an array. */
  nin?: Maybe<Array<Scalars['Float']>>,
  /** Matches values that are equal to a specified value. */
  eq?: Maybe<Scalars['Float']>,
  /** Matches values that are greater than a specified value. */
  gt?: Maybe<Scalars['Float']>,
  /** Matches values that are greater than or equal to a specified value. */
  gte?: Maybe<Scalars['Float']>,
  /** Matches values that are less than a specified value. */
  lt?: Maybe<Scalars['Float']>,
  /** Matches values that are less than or equal to a specified value. */
  lte?: Maybe<Scalars['Float']>,
  /** Matches all values that are not equal to a specified value. */
  ne?: Maybe<Scalars['Float']>,
};

export type Interval = Node & {
   __typename?: 'Interval',
  id: Scalars['ID'],
  start: Scalars['DateTime'],
  end: Scalars['DateTime'],
  /** estimated hours */
  duration: Scalars['Int'],
  /** Minimum number of experts per interval */
  min: Scalars['Int'],
  /** Maximum number of experts per interval */
  max: Scalars['Int'],
  /** Category od requested specialist */
  category: Category,
  knowledge: Knowledge,
  skills: SkillConnection,
};


export type IntervalSkillsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SkillFilter>
};

export type IntervalConnection = {
   __typename?: 'IntervalConnection',
  pageInfo: PageInfo,
  edges: Array<IntervalEdge>,
  aggregate: AggregateInterval,
};

export type IntervalEdge = {
   __typename?: 'IntervalEdge',
  node: Interval,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type IntervalFilter = {
  start?: Maybe<StringFilter>,
  end?: Maybe<StringFilter>,
  duration?: Maybe<IntFilter>,
  and?: Maybe<Array<IntervalFilter>>,
  or?: Maybe<Array<IntervalFilter>>,
};

export type IntervalInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  start: Scalars['DateTime'],
  end: Scalars['DateTime'],
  /** estimated hours */
  duration: Scalars['Int'],
  /** Minimum number of experts per interval */
  min: Scalars['Int'],
  /** Maximum number of experts per interval */
  max: Scalars['Int'],
  /** Required area of knowledge */
  knowledge: Scalars['RelayID'],
  /** Category of requested specialist */
  category: Category,
  /** Required skills in the area of knowledge */
  skills: Array<Scalars['RelayID']>,
};

export type IntFilter = {
  /** Matches any of the values specified in an array. */
  in?: Maybe<Array<Scalars['Int']>>,
  /** Matches none of the values specified in an array. */
  nin?: Maybe<Array<Scalars['Int']>>,
  /** Matches values that are equal to a specified value. */
  eq?: Maybe<Scalars['Int']>,
  /** Matches values that are greater than a specified value. */
  gt?: Maybe<Scalars['Int']>,
  /** Matches values that are greater than or equal to a specified value. */
  gte?: Maybe<Scalars['Int']>,
  /** Matches values that are less than a specified value. */
  lt?: Maybe<Scalars['Int']>,
  /** Matches values that are less than or equal to a specified value. */
  lte?: Maybe<Scalars['Int']>,
  /** Matches all values that are not equal to a specified value. */
  ne?: Maybe<Scalars['Int']>,
};

export type Knowledge = Node & {
   __typename?: 'Knowledge',
  id: Scalars['ID'],
  name: Scalars['String'],
  skills: SkillConnection,
};


export type KnowledgeSkillsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SkillFilter>
};

export type KnowledgeConnection = {
   __typename?: 'KnowledgeConnection',
  pageInfo: PageInfo,
  edges: Array<KnowledgeEdge>,
  aggregate: AggregateKnowledge,
};

export type KnowledgeEdge = {
   __typename?: 'KnowledgeEdge',
  node: Knowledge,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type KnowledgeFilter = {
  name?: Maybe<StringFilter>,
  and?: Maybe<Array<KnowledgeFilter>>,
  or?: Maybe<Array<KnowledgeFilter>>,
};

export type KnowledgeInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  skills: Array<Scalars['RelayID']>,
};

export type LocalPetition = {
   __typename?: 'LocalPetition',
  ticketType: Scalars['String'],
  client: Scalars['String'],
  project?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  proposal?: Maybe<Scalars['String']>,
  priority: Scalars['Int'],
  link?: Maybe<Scalars['Boolean']>,
  related?: Maybe<Array<Maybe<Scalars['String']>>>,
  linked?: Maybe<Scalars['String']>,
};

export type LocalPetitionInput = {
  ticketType: Scalars['String'],
  client: Scalars['String'],
  project?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  proposal?: Maybe<Scalars['String']>,
  priority: Scalars['Int'],
  link?: Maybe<Scalars['Boolean']>,
  related?: Maybe<Array<Maybe<Scalars['String']>>>,
  linked?: Maybe<Scalars['String']>,
};

export type LoginInput = {
  email: Scalars['String'],
  password: Scalars['String'],
};

export type LoginPayload = {
   __typename?: 'LoginPayload',
  accessToken: Scalars['String'],
};

export type Mutation = {
   __typename?: 'Mutation',
  createTag: CreateTagPayload,
  deleteTag: Tag,
  createProject: CreateProjectPayload,
  deleteProject: Project,
  createClient: CreateClientPayload,
  deleteClient: Client,
  createKnowledge: CreateKnowledgePayload,
  deleteKnowledge: Knowledge,
  createSkill: CreateSkillPayload,
  deleteSkill: Skill,
  createInterval: CreateIntervalPayload,
  deleteInterval: Interval,
  createPetitionType: CreatePetitionTypePayload,
  deletePetitionType: PetitionType,
  createRole: CreateRolePayload,
  deleteRoles: Role,
  createPermission: CreatePermissionPayload,
  deletePermission: Permission,
  deleteUser: User,
  login: LoginPayload,
  createUser: CreateUserPayload,
  createPetition: CreatePetitionPayload,
  createProjectAssignment: CreatePetitionPayload,
  addAction: Scalars['Boolean'],
  deletePetition: Petition,
  aprovePetition: Scalars['Boolean'],
  rejectPetition: Scalars['Boolean'],
  createProposalForPetition: Scalars['Boolean'],
  deleteProposal: Proposal,
  createAssignament: CreateAssignamentPayload,
  deleteAssignament: Assignament,
  uploadFile: Scalars['String'],
  setCurrentLocalPetition?: Maybe<LocalPetition>,
};


export type MutationCreateTagArgs = {
  input: TagInput
};


export type MutationDeleteTagArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateProjectArgs = {
  input: ProjectInput
};


export type MutationDeleteProjectArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateClientArgs = {
  input: ClientInput
};


export type MutationDeleteClientArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateKnowledgeArgs = {
  input: KnowledgeInput
};


export type MutationDeleteKnowledgeArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateSkillArgs = {
  input: SkillInput
};


export type MutationDeleteSkillArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateIntervalArgs = {
  input: IntervalInput
};


export type MutationDeleteIntervalArgs = {
  id: Scalars['RelayID']
};


export type MutationCreatePetitionTypeArgs = {
  input: PetitionTypeInput
};


export type MutationDeletePetitionTypeArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateRoleArgs = {
  input: RoleInput
};


export type MutationDeleteRolesArgs = {
  id: Scalars['RelayID']
};


export type MutationCreatePermissionArgs = {
  input: PermissionInput
};


export type MutationDeletePermissionArgs = {
  id: Scalars['RelayID']
};


export type MutationDeleteUserArgs = {
  id: Scalars['RelayID']
};


export type MutationLoginArgs = {
  input: LoginInput
};


export type MutationCreateUserArgs = {
  input: UserInput
};


export type MutationCreatePetitionArgs = {
  input: PetitionInput
};


export type MutationCreateProjectAssignmentArgs = {
  input: ProjectAssignmentInput,
  draft: Scalars['RelayID']
};


export type MutationAddActionArgs = {
  input: ActionInput,
  draft: Scalars['RelayID']
};


export type MutationDeletePetitionArgs = {
  id: Scalars['RelayID']
};


export type MutationAprovePetitionArgs = {
  id: Scalars['RelayID']
};


export type MutationRejectPetitionArgs = {
  id: Scalars['RelayID'],
  comment: Scalars['String']
};


export type MutationCreateProposalForPetitionArgs = {
  input: ProposalInput,
  petition: Scalars['RelayID']
};


export type MutationDeleteProposalArgs = {
  id: Scalars['RelayID']
};


export type MutationCreateAssignamentArgs = {
  input: AssignamentInput
};


export type MutationDeleteAssignamentArgs = {
  id: Scalars['RelayID']
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']
};


export type MutationSetCurrentLocalPetitionArgs = {
  input: LocalPetitionInput
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'],
};

/** Information about pagination in a connection. */
export type PageInfo = {
   __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage?: Maybe<Scalars['Boolean']>,
  /** When paginating backwards, are there more items? */
  hasPreviousPage?: Maybe<Scalars['Boolean']>,
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
};

/** Define all permissions in the app */
export type Permission = Node & {
   __typename?: 'Permission',
  id: Scalars['ID'],
  /** Permission name */
  name: Scalars['String'],
};

export type PermissionConnection = {
   __typename?: 'PermissionConnection',
  pageInfo: PageInfo,
  edges: Array<PermissionEdge>,
  aggregate: AggregatePermission,
};

export type PermissionEdge = {
   __typename?: 'PermissionEdge',
  node: Permission,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type PermissionFilter = {
  name?: Maybe<StringFilter>,
  and?: Maybe<Array<PermissionFilter>>,
  or?: Maybe<Array<PermissionFilter>>,
};

export type PermissionInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
};

export type Petition = Node & {
   __typename?: 'Petition',
  id: Scalars['ID'],
  /** The timestamp when the record was first created */
  createdAt: Scalars['DateTime'],
  /** The timestamp of the most recent change to any field of the record */
  updatedAt: Scalars['DateTime'],
  /** Type of petition */
  type: PetitionType,
  code?: Maybe<Scalars['String']>,
  /** Priority of petition */
  priority: Scalars['Int'],
  /** Current status of petition */
  status: Status,
  deadLine?: Maybe<Scalars['DateTime']>,
  /** url of previous created files */
  documents?: Maybe<Array<Scalars['String']>>,
  timeline: Array<Action>,
  cost: Scalars['Float'],
  intervals: IntervalConnection,
  proposals: ProposalConnection,
  /** User who creates the petition */
  owner: User,
  client: Client,
  related: PetitionConnection,
  /** Project associated to this petition */
  project?: Maybe<Project>,
  /** estimated start date */
  start?: Maybe<Scalars['DateTime']>,
  /** estimated end date */
  end?: Maybe<Scalars['DateTime']>,
  /** min - max experts */
  experts?: Maybe<Experts>,
};


export type PetitionIntervalsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<IntervalFilter>
};


export type PetitionProposalsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ProposalFilter>
};


export type PetitionRelatedArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PetitionFilter>
};

export type PetitionConnection = {
   __typename?: 'PetitionConnection',
  pageInfo: PageInfo,
  edges: Array<PetitionEdge>,
  aggregate: AggregatePetition,
};

export type PetitionEdge = {
   __typename?: 'PetitionEdge',
  node: Petition,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type PetitionFilter = {
  code?: Maybe<StringFilter>,
  and?: Maybe<Array<PetitionFilter>>,
  or?: Maybe<Array<PetitionFilter>>,
};

export type PetitionInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  type: Scalars['RelayID'],
  client: Scalars['RelayID'],
  project?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  proposal?: Maybe<Scalars['String']>,
  priority: Scalars['Int'],
  related?: Maybe<Array<Scalars['RelayID']>>,
  cost?: Maybe<Scalars['Float']>,
};

export type PetitionType = Node & {
   __typename?: 'PetitionType',
  id: Scalars['ID'],
  name: Scalars['String'],
  code: Scalars['String'],
  duration: Duration,
  factors: Array<Factors>,
};

export type PetitionTypeConnection = {
   __typename?: 'PetitionTypeConnection',
  pageInfo: PageInfo,
  edges: Array<PetitionTypeEdge>,
  aggregate: AggregatePetitionType,
};

export type PetitionTypeEdge = {
   __typename?: 'PetitionTypeEdge',
  node: PetitionType,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type PetitionTypeInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  code: Scalars['String'],
  duration: DurationInput,
  factors: Array<Factors>,
};

export type Project = Node & {
   __typename?: 'Project',
  id: Scalars['ID'],
  name: Scalars['String'],
  client: Client,
  tags?: Maybe<TagConnection>,
};


export type ProjectTagsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<TagFilter>
};

export type ProjectAssignmentInput = {
  deadLine: Scalars['DateTime'],
  intervals: Array<IntervalInput>,
  comment: Scalars['String'],
  /** url of previous created files */
  documents: Array<Scalars['String']>,
};

export type ProjectConnection = {
   __typename?: 'ProjectConnection',
  pageInfo: PageInfo,
  edges: Array<ProjectEdge>,
  aggregate: AggregateProject,
};

export type ProjectEdge = {
   __typename?: 'ProjectEdge',
  node: Project,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type ProjectFilter = {
  name?: Maybe<StringFilter>,
  and?: Maybe<Array<ProjectFilter>>,
  or?: Maybe<Array<ProjectFilter>>,
};

export type ProjectInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  client: Scalars['RelayID'],
  tags: Array<Scalars['RelayID']>,
};

export type Proposal = Node & {
   __typename?: 'Proposal',
  id: Scalars['ID'],
  manager: User,
  status: ProposalStatus,
  petition: Petition,
  assignaments: AssignamentConnection,
};


export type ProposalAssignamentsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AssignamentFilter>
};

export type ProposalConnection = {
   __typename?: 'ProposalConnection',
  pageInfo: PageInfo,
  edges: Array<ProposalEdge>,
  aggregate: AggregateProposal,
};

export type ProposalEdge = {
   __typename?: 'ProposalEdge',
  node: Proposal,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type ProposalFilter = {
  status?: Maybe<StringFilter>,
  and?: Maybe<Array<ProposalFilter>>,
  or?: Maybe<Array<ProposalFilter>>,
};

export type ProposalInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  status?: Maybe<ProposalStatus>,
  assignaments: Array<AssignamentInput>,
};

/** Status of proposal */
export enum ProposalStatus {
  CANCELED = 'CANCELED',
  APROVED = 'APROVED',
  PENDING = 'PENDING'
}

export type Query = {
   __typename?: 'Query',
  tags: TagConnection,
  tag: Tag,
  projects: ProjectConnection,
  project: Project,
  clients: ClientConnection,
  client: Client,
  singleKnowledge: Knowledge,
  knowledge: KnowledgeConnection,
  skills: SkillConnection,
  skill: Skill,
  intervals: IntervalConnection,
  interval: Interval,
  petitionTypes: PetitionTypeConnection,
  petitionType: PetitionType,
  roles: RoleConnection,
  role: Role,
  permissions: PermissionConnection,
  permission: Permission,
  users: UserConnection,
  user: User,
  me: User,
  specialistCategory: Array<Category>,
  petitions: PetitionConnection,
  petition: Petition,
  suggestSpecialists: Array<SuggestedSpecialist>,
  proposals: ProposalConnection,
  proposal: Proposal,
  assignaments: AssignamentConnection,
  assignament: Assignament,
  /** Fetches an object given its ID */
  node?: Maybe<Node>,
  isLoggedIn: Scalars['Boolean'],
  currentLocalPetition?: Maybe<LocalPetition>,
};


export type QueryTagsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<TagFilter>
};


export type QueryTagArgs = {
  id: Scalars['RelayID']
};


export type QueryProjectsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ProjectFilter>
};


export type QueryProjectArgs = {
  id: Scalars['RelayID']
};


export type QueryClientsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ClientFilter>
};


export type QueryClientArgs = {
  id: Scalars['RelayID']
};


export type QuerySingleKnowledgeArgs = {
  id: Scalars['RelayID']
};


export type QueryKnowledgeArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<KnowledgeFilter>
};


export type QuerySkillsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SkillFilter>
};


export type QuerySkillArgs = {
  id: Scalars['RelayID']
};


export type QueryIntervalsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<IntervalFilter>
};


export type QueryIntervalArgs = {
  id: Scalars['RelayID']
};


export type QueryPetitionTypesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPetitionTypeArgs = {
  id: Scalars['RelayID']
};


export type QueryRolesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<RoleFilter>
};


export type QueryRoleArgs = {
  id: Scalars['RelayID']
};


export type QueryPermissionsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PermissionFilter>
};


export type QueryPermissionArgs = {
  id: Scalars['RelayID']
};


export type QueryUsersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UserFilter>
};


export type QueryUserArgs = {
  id: Scalars['RelayID']
};


export type QueryPetitionsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PetitionFilter>
};


export type QueryPetitionArgs = {
  id: Scalars['RelayID']
};


export type QuerySuggestSpecialistsArgs = {
  input: SuggestSpecialistInput
};


export type QueryProposalsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ProposalFilter>
};


export type QueryProposalArgs = {
  id: Scalars['RelayID']
};


export type QueryAssignamentsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<AssignamentFilter>
};


export type QueryAssignamentArgs = {
  id: Scalars['RelayID']
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};


export type Role = Node & {
   __typename?: 'Role',
  id: Scalars['ID'],
  name: Scalars['String'],
  permissions: PermissionConnection,
  users?: Maybe<UserConnection>,
};


export type RolePermissionsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PermissionFilter>
};


export type RoleUsersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UserFilter>
};

export type RoleConnection = {
   __typename?: 'RoleConnection',
  pageInfo: PageInfo,
  edges: Array<RoleEdge>,
  aggregate: AggregateRole,
};

export type RoleEdge = {
   __typename?: 'RoleEdge',
  node: Role,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type RoleFilter = {
  name?: Maybe<StringFilter>,
  and?: Maybe<Array<RoleFilter>>,
  or?: Maybe<Array<RoleFilter>>,
};

export type RoleInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  permissions: Array<Scalars['RelayID']>,
};

export type Skill = Node & {
   __typename?: 'Skill',
  id: Scalars['ID'],
  name: Scalars['String'],
  level: Scalars['Int'],
};

export type SkillConnection = {
   __typename?: 'SkillConnection',
  pageInfo: PageInfo,
  edges: Array<SkillEdge>,
  aggregate: AggregateSkill,
};

export type SkillEdge = {
   __typename?: 'SkillEdge',
  node: Skill,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type SkillFilter = {
  name?: Maybe<StringFilter>,
  level?: Maybe<IntFilter>,
  and?: Maybe<Array<SkillFilter>>,
  or?: Maybe<Array<SkillFilter>>,
};

export type SkillInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  level: Scalars['Int'],
};

/** Status of a petition */
export enum Status {
  PENDING_TO_ASSIGNAMENT = 'PENDING_TO_ASSIGNAMENT',
  PENDING_TO_REVIEW_PROPOSAL = 'PENDING_TO_REVIEW_PROPOSAL',
  CANCELED = 'CANCELED',
  APROVED = 'APROVED',
  DRAFT = 'DRAFT'
}

export type StringFilter = {
  /** Matches any of the values specified in an array. */
  in?: Maybe<Array<Scalars['String']>>,
  /** Matches none of the values specified in an array. */
  nin?: Maybe<Array<Scalars['String']>>,
  /** Matches values that are equal to a specified value. */
  eq?: Maybe<Scalars['String']>,
  /** Matches values that are greater than a specified value. */
  gt?: Maybe<Scalars['String']>,
  /** Matches values that are greater than or equal to a specified value. */
  gte?: Maybe<Scalars['String']>,
  /** Matches values that are less than a specified value. */
  lt?: Maybe<Scalars['String']>,
  /** Matches values that are less than or equal to a specified value. */
  lte?: Maybe<Scalars['String']>,
  /** Matches all values that are not equal to a specified value. */
  ne?: Maybe<Scalars['String']>,
};

export type SuggestedSpecialist = {
   __typename?: 'SuggestedSpecialist',
  user: User,
  points: SuggestionPoints,
};

export type SuggestionPoints = {
   __typename?: 'SuggestionPoints',
  skillsMatch: Scalars['Float'],
  assignamentDays: Array<Scalars['DateTime']>,
  projectsMatch: Scalars['Float'],
  disponibility: Scalars['Float'],
};

export type SuggestSpecialistInput = {
  petition: Scalars['RelayID'],
  /** Preferred skills */
  skills: Array<Scalars['RelayID']>,
  catergory: Array<Category>,
};

export type Tag = Node & {
   __typename?: 'Tag',
  id: Scalars['ID'],
  name: Scalars['String'],
  category: Scalars['String'],
};

export type TagConnection = {
   __typename?: 'TagConnection',
  pageInfo: PageInfo,
  edges: Array<TagEdge>,
  aggregate: AggregateTag,
};

export type TagEdge = {
   __typename?: 'TagEdge',
  node: Tag,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type TagFilter = {
  name?: Maybe<StringFilter>,
  category?: Maybe<StringFilter>,
  and?: Maybe<Array<TagFilter>>,
  or?: Maybe<Array<TagFilter>>,
};

export type TagInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  category: Scalars['String'],
};


export type User = Node & {
   __typename?: 'User',
  id: Scalars['ID'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  category?: Maybe<Category>,
  roles: RoleConnection,
  name: Scalars['String'],
  skills: SkillConnection,
};


export type UserRolesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<RoleFilter>
};


export type UserSkillsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SkillFilter>
};

export type UserConnection = {
   __typename?: 'UserConnection',
  pageInfo: PageInfo,
  edges: Array<UserEdge>,
  aggregate: AggregateUser,
};

export type UserEdge = {
   __typename?: 'UserEdge',
  node: User,
  /** Used in before and after args */
  cursor: Scalars['String'],
};

export type UserFilter = {
  firstName?: Maybe<StringFilter>,
  lastName?: Maybe<StringFilter>,
  email?: Maybe<StringFilter>,
  and?: Maybe<Array<UserFilter>>,
  or?: Maybe<Array<UserFilter>>,
};

export type UserInput = {
  /** Allow us to track the mutation's progress. */
  clientMutationId?: Maybe<Scalars['String']>,
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  password: Scalars['String'],
  category?: Maybe<Category>,
  roles?: Maybe<Array<Scalars['RelayID']>>,
  skills?: Maybe<Array<Scalars['RelayID']>>,
};

export type AlertDataQueryVariables = {
  id: Scalars['RelayID']
};


export type AlertDataQuery = { __typename?: 'Query', petition: { __typename?: 'Petition', id: string, code: Maybe<string>, priority: number, project: Maybe<{ __typename?: 'Project', name: string }>, client: { __typename?: 'Client', name: string } } };

export type CurrentLocalPetitionQueryVariables = {};


export type CurrentLocalPetitionQuery = { __typename?: 'Query', currentLocalPetition: Maybe<{ __typename?: 'LocalPetition', ticketType: string, client: string, project: Maybe<string>, code: Maybe<string>, priority: number, link: Maybe<boolean>, related: Maybe<Array<Maybe<string>>> }> };

export type SetCurrentLocalPetitionMutationVariables = {
  input: LocalPetitionInput
};


export type SetCurrentLocalPetitionMutation = { __typename?: 'Mutation', setCurrentLocalPetition: Maybe<{ __typename?: 'LocalPetition', ticketType: string, client: string, project: Maybe<string>, code: Maybe<string>, priority: number, link: Maybe<boolean>, related: Maybe<Array<Maybe<string>>> }> };

export type UploadFileMutationVariables = {
  file: Scalars['Upload']
};


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: string };

export type LogInMutationVariables = {
  input: LoginInput
};


export type LogInMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginPayload', accessToken: string } };

export type IsUserLoggedInQueryVariables = {};


export type IsUserLoggedInQuery = { __typename?: 'Query', isLoggedIn: boolean };

export type PetitionTypesQueryVariables = {};


export type PetitionTypesQuery = { __typename?: 'Query', petitionTypes: { __typename?: 'PetitionTypeConnection', edges: Array<{ __typename?: 'PetitionTypeEdge', node: { __typename?: 'PetitionType', id: string, name: string, code: string } }> } };

export type PetitionTypeQueryVariables = {
  id: Scalars['RelayID']
};


export type PetitionTypeQuery = { __typename?: 'Query', petitionType: { __typename?: 'PetitionType', code: string } };

export type ClientsQueryVariables = {};


export type ClientsQuery = { __typename?: 'Query', clients: { __typename?: 'ClientConnection', edges: Array<{ __typename?: 'ClientEdge', node: { __typename?: 'Client', id: string, name: string } }> } };

export type ClientPetitionsQueryVariables = {
  id: Scalars['RelayID']
};


export type ClientPetitionsQuery = { __typename?: 'Query', client: { __typename?: 'Client', name: string, petitions: { __typename?: 'PetitionConnection', edges: Array<{ __typename?: 'PetitionEdge', node: { __typename?: 'Petition', id: string, type: { __typename?: 'PetitionType', name: string }, project: Maybe<{ __typename?: 'Project', name: string }> } }> } } };

export type PetitionFragFragment = { __typename?: 'Petition', id: string, code: Maybe<string>, deadLine: Maybe<string>, status: Status, createdAt: string, start: Maybe<string>, end: Maybe<string>, project: Maybe<{ __typename?: 'Project', name: string }>, intervals: { __typename?: 'IntervalConnection', edges: Array<{ __typename?: 'IntervalEdge', node: { __typename?: 'Interval', knowledge: { __typename?: 'Knowledge', name: string } } }> }, type: { __typename?: 'PetitionType', code: string }, experts: Maybe<{ __typename?: 'Experts', min: number, max: number }> };

export type PetitionsQueryVariables = {};


export type PetitionsQuery = { __typename?: 'Query', petitions: { __typename?: 'PetitionConnection', edges: Array<{ __typename?: 'PetitionEdge', node: (
        { __typename?: 'Petition' }
        & PetitionFragFragment
      ) }> } };

export type SkillsFragmentFragment = { __typename?: 'Interval', skills: { __typename?: 'SkillConnection', edges: Array<{ __typename?: 'SkillEdge', node: { __typename?: 'Skill', id: string, name: string } }> } };

export type IntervalsNamesAndSkillsFragmentFragment = { __typename?: 'Petition', intervals: { __typename?: 'IntervalConnection', edges: Array<{ __typename?: 'IntervalEdge', node: (
        { __typename?: 'Interval', category: Category, duration: number, start: string, end: string, knowledge: { __typename?: 'Knowledge', id: string, name: string } }
        & SkillsFragmentFragment
      ) }> } };

export type ManagePetitionQueryVariables = {
  id: Scalars['RelayID']
};


export type ManagePetitionQuery = { __typename?: 'Query', petition: (
    { __typename?: 'Petition', id: string, start: Maybe<string>, end: Maybe<string> }
    & IntervalsNamesAndSkillsFragmentFragment
  ) };

export type IntervalsFragmentFragment = { __typename?: 'Petition', intervals: { __typename?: 'IntervalConnection', edges: Array<{ __typename?: 'IntervalEdge', node: (
        { __typename?: 'Interval', category: Category, min: number, max: number, duration: number, start: string, knowledge: { __typename?: 'Knowledge', name: string } }
        & SkillsFragmentFragment
      ) }> } };

export type CommentsFragmentFragment = { __typename?: 'Petition', timeline: Array<{ __typename?: 'Action', type: ActionType, comment: Maybe<string>, createdAt: string, user: { __typename?: 'User', name: string } }> };

export type PetitionDetailsQueryVariables = {
  id: Scalars['RelayID']
};


export type PetitionDetailsQuery = { __typename?: 'Query', petition: (
    { __typename?: 'Petition', id: string, code: Maybe<string>, status: Status, documents: Maybe<Array<string>>, deadLine: Maybe<string>, end: Maybe<string>, project: Maybe<{ __typename?: 'Project', name: string }>, experts: Maybe<{ __typename?: 'Experts', min: number }> }
    & CommentsFragmentFragment
    & IntervalsFragmentFragment
  ) };

export type CreateDraftPetitionMutationVariables = {
  input: PetitionInput
};


export type CreateDraftPetitionMutation = { __typename?: 'Mutation', createPetition: { __typename?: 'CreatePetitionPayload', clientMutationId: Maybe<string>, petition: (
      { __typename?: 'Petition' }
      & ClientProjectPriorityFragment
    ) } };

export type ClientProjectPriorityFragment = { __typename?: 'Petition', id: string, priority: number, type: { __typename?: 'PetitionType', name: string }, client: { __typename?: 'Client', name: string }, project: Maybe<{ __typename?: 'Project', name: string }> };

export type CreateProjectAssignmentMutationVariables = {
  input: ProjectAssignmentInput,
  draft: Scalars['RelayID']
};


export type CreateProjectAssignmentMutation = { __typename?: 'Mutation', createProjectAssignment: { __typename?: 'CreatePetitionPayload', petition: { __typename?: 'Petition', id: string } } };

export type ProjectNameQueryVariables = {
  id: Scalars['RelayID']
};


export type ProjectNameQuery = { __typename?: 'Query', petition: { __typename?: 'Petition', project: Maybe<{ __typename?: 'Project', name: string }> } };

export type AddPetitonActionMutationVariables = {
  input: ActionInput,
  draft: Scalars['RelayID']
};


export type AddPetitonActionMutation = { __typename?: 'Mutation', addAction: boolean };

export type StepSummaryQueryVariables = {
  id: Scalars['RelayID']
};


export type StepSummaryQuery = { __typename?: 'Query', petition: { __typename?: 'Petition', end: Maybe<string>, deadLine: Maybe<string>, experts: Maybe<{ __typename?: 'Experts', min: number }>, project: Maybe<{ __typename?: 'Project', name: string }>, intervals: { __typename?: 'IntervalConnection', edges: Array<{ __typename?: 'IntervalEdge', node: { __typename?: 'Interval', knowledge: { __typename?: 'Knowledge', name: string } } }> } } };

export type CategoriesAndSkillsQueryVariables = {
  id: Scalars['RelayID']
};


export type CategoriesAndSkillsQuery = { __typename?: 'Query', specialistCategory: Array<Category>, singleKnowledge: { __typename?: 'Knowledge', id: string, skills: { __typename?: 'SkillConnection', edges: Array<{ __typename?: 'SkillEdge', node: { __typename?: 'Skill', id: string, name: string } }> } } };

export type SuggestSpecialistQueryVariables = {
  input: SuggestSpecialistInput
};


export type SuggestSpecialistQuery = { __typename?: 'Query', suggestSpecialists: Array<{ __typename?: 'SuggestedSpecialist', user: { __typename?: 'User', id: string, name: string, category: Maybe<Category> }, points: { __typename?: 'SuggestionPoints', skillsMatch: number, assignamentDays: Array<string> } }> };

export type AddProposalToPetitionMutationVariables = {
  petition: Scalars['RelayID'],
  input: ProposalInput
};


export type AddProposalToPetitionMutation = { __typename?: 'Mutation', createProposalForPetition: boolean };

export type AprovePetitionMutationVariables = {
  id: Scalars['RelayID']
};


export type AprovePetitionMutation = { __typename?: 'Mutation', aprovePetition: boolean };

export type RejectPetitionMutationVariables = {
  id: Scalars['RelayID'],
  comment: Scalars['String']
};


export type RejectPetitionMutation = { __typename?: 'Mutation', rejectPetition: boolean };

export type RegisterMutationVariables = {
  input: UserInput
};


export type RegisterMutation = { __typename?: 'Mutation', createUser: { __typename?: 'CreateUserPayload', user: { __typename?: 'User', id: string } } };

export type ResumenDataQueryVariables = {
  id: Scalars['RelayID']
};


export type ResumenDataQuery = { __typename?: 'Query', petition: { __typename?: 'Petition', start: Maybe<string>, end: Maybe<string>, proposals: { __typename?: 'ProposalConnection', edges: Array<{ __typename?: 'ProposalEdge', node: { __typename?: 'Proposal', id: string, assignaments: { __typename?: 'AssignamentConnection', edges: Array<{ __typename?: 'AssignamentEdge', node: { __typename?: 'Assignament', id: string, days: Array<string>, expert: { __typename?: 'User', id: string, name: string, category: Maybe<Category> } } }> } } }> } } };

export type SkillsFragment = { __typename?: 'Knowledge', skills: { __typename?: 'SkillConnection', edges: Array<{ __typename?: 'SkillEdge', node: { __typename?: 'Skill', id: string, name: string } }> } };

export type SingleKnowledgeByIdQueryVariables = {
  id: Scalars['RelayID']
};


export type SingleKnowledgeByIdQuery = { __typename?: 'Query', singleKnowledge: { __typename?: 'Knowledge', name: string } };

export type SingleKnowledgeFragment = { __typename?: 'Knowledge', id: string, name: string };

export type KnowledgeSkillsQueryVariables = {};


export type KnowledgeSkillsQuery = { __typename?: 'Query', specialistCategory: Array<Category>, knowledge: { __typename?: 'KnowledgeConnection', edges: Array<{ __typename?: 'KnowledgeEdge', node: (
        { __typename?: 'Knowledge' }
        & SingleKnowledgeFragment
        & SkillsFragment
      ) }> } };

export type SkillsAndCategoriesQueryVariables = {};


export type SkillsAndCategoriesQuery = { __typename?: 'Query', specialistCategory: Array<Category>, skills: { __typename?: 'SkillConnection', edges: Array<{ __typename?: 'SkillEdge', node: { __typename?: 'Skill', id: string, name: string } }> } };

export const PetitionFragFragmentDoc = gql`
    fragment petitionFrag on Petition {
  id
  code
  project {
    name
  }
  intervals {
    edges {
      node {
        knowledge {
          name
        }
      }
    }
  }
  type {
    code
  }
  deadLine
  status
  createdAt
  start
  end
  experts {
    min
    max
  }
}
    `;
export const SkillsFragmentFragmentDoc = gql`
    fragment skillsFragment on Interval {
  skills {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const IntervalsNamesAndSkillsFragmentFragmentDoc = gql`
    fragment intervalsNamesAndSkillsFragment on Petition {
  intervals {
    edges {
      node {
        category
        duration
        start
        end
        knowledge {
          id
          name
        }
        ...skillsFragment
      }
    }
  }
}
    ${SkillsFragmentFragmentDoc}`;
export const IntervalsFragmentFragmentDoc = gql`
    fragment intervalsFragment on Petition {
  intervals {
    edges {
      node {
        knowledge {
          name
        }
        ...skillsFragment
        category
        min
        max
        duration
        start
      }
    }
  }
}
    ${SkillsFragmentFragmentDoc}`;
export const CommentsFragmentFragmentDoc = gql`
    fragment commentsFragment on Petition {
  timeline {
    type
    comment
    user {
      name
    }
    createdAt
  }
}
    `;
export const ClientProjectPriorityFragmentDoc = gql`
    fragment ClientProjectPriority on Petition {
  id
  type {
    name
  }
  client {
    name
  }
  project {
    name
  }
  priority
}
    `;
export const SkillsFragmentDoc = gql`
    fragment skills on Knowledge {
  skills {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const SingleKnowledgeFragmentDoc = gql`
    fragment singleKnowledge on Knowledge {
  id
  name
}
    `;
export const AlertDataDocument = gql`
    query alertData($id: RelayID!) {
  petition(id: $id) {
    id
    code
    project {
      name
    }
    client {
      name
    }
    priority
  }
}
    `;

/**
 * __useAlertDataQuery__
 *
 * To run a query within a React component, call `useAlertDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAlertDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AlertDataQuery, AlertDataQueryVariables>) {
        return ApolloReactHooks.useQuery<AlertDataQuery, AlertDataQueryVariables>(AlertDataDocument, baseOptions);
      }
export function useAlertDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlertDataQuery, AlertDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AlertDataQuery, AlertDataQueryVariables>(AlertDataDocument, baseOptions);
        }
export type AlertDataQueryHookResult = ReturnType<typeof useAlertDataQuery>;
export type AlertDataLazyQueryHookResult = ReturnType<typeof useAlertDataLazyQuery>;
export type AlertDataQueryResult = ApolloReactCommon.QueryResult<AlertDataQuery, AlertDataQueryVariables>;
export const CurrentLocalPetitionDocument = gql`
    query currentLocalPetition {
  currentLocalPetition @client {
    ticketType
    client
    project
    code
    priority
    link
    related
  }
}
    `;

/**
 * __useCurrentLocalPetitionQuery__
 *
 * To run a query within a React component, call `useCurrentLocalPetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentLocalPetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentLocalPetitionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentLocalPetitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentLocalPetitionQuery, CurrentLocalPetitionQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentLocalPetitionQuery, CurrentLocalPetitionQueryVariables>(CurrentLocalPetitionDocument, baseOptions);
      }
export function useCurrentLocalPetitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentLocalPetitionQuery, CurrentLocalPetitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentLocalPetitionQuery, CurrentLocalPetitionQueryVariables>(CurrentLocalPetitionDocument, baseOptions);
        }
export type CurrentLocalPetitionQueryHookResult = ReturnType<typeof useCurrentLocalPetitionQuery>;
export type CurrentLocalPetitionLazyQueryHookResult = ReturnType<typeof useCurrentLocalPetitionLazyQuery>;
export type CurrentLocalPetitionQueryResult = ApolloReactCommon.QueryResult<CurrentLocalPetitionQuery, CurrentLocalPetitionQueryVariables>;
export const SetCurrentLocalPetitionDocument = gql`
    mutation setCurrentLocalPetition($input: LocalPetitionInput!) {
  setCurrentLocalPetition(input: $input) @client {
    ticketType
    client
    project
    code
    priority
    link
    related
  }
}
    `;
export type SetCurrentLocalPetitionMutationFn = ApolloReactCommon.MutationFunction<SetCurrentLocalPetitionMutation, SetCurrentLocalPetitionMutationVariables>;

/**
 * __useSetCurrentLocalPetitionMutation__
 *
 * To run a mutation, you first call `useSetCurrentLocalPetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentLocalPetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentLocalPetitionMutation, { data, loading, error }] = useSetCurrentLocalPetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCurrentLocalPetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCurrentLocalPetitionMutation, SetCurrentLocalPetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCurrentLocalPetitionMutation, SetCurrentLocalPetitionMutationVariables>(SetCurrentLocalPetitionDocument, baseOptions);
      }
export type SetCurrentLocalPetitionMutationHookResult = ReturnType<typeof useSetCurrentLocalPetitionMutation>;
export type SetCurrentLocalPetitionMutationResult = ApolloReactCommon.MutationResult<SetCurrentLocalPetitionMutation>;
export type SetCurrentLocalPetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCurrentLocalPetitionMutation, SetCurrentLocalPetitionMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!) {
  uploadFile(file: $file)
}
    `;
export type UploadFileMutationFn = ApolloReactCommon.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const LogInDocument = gql`
    mutation LogIn($input: LoginInput!) {
  login(input: $input) {
    accessToken
  }
}
    `;
export type LogInMutationFn = ApolloReactCommon.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        return ApolloReactHooks.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, baseOptions);
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = ApolloReactCommon.MutationResult<LogInMutation>;
export type LogInMutationOptions = ApolloReactCommon.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const IsUserLoggedInDocument = gql`
    query IsUserLoggedIn {
  isLoggedIn @client
}
    `;

/**
 * __useIsUserLoggedInQuery__
 *
 * To run a query within a React component, call `useIsUserLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserLoggedInQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>) {
        return ApolloReactHooks.useQuery<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>(IsUserLoggedInDocument, baseOptions);
      }
export function useIsUserLoggedInLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>(IsUserLoggedInDocument, baseOptions);
        }
export type IsUserLoggedInQueryHookResult = ReturnType<typeof useIsUserLoggedInQuery>;
export type IsUserLoggedInLazyQueryHookResult = ReturnType<typeof useIsUserLoggedInLazyQuery>;
export type IsUserLoggedInQueryResult = ApolloReactCommon.QueryResult<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>;
export const PetitionTypesDocument = gql`
    query petitionTypes {
  petitionTypes {
    edges {
      node {
        id
        name
        code
      }
    }
  }
}
    `;

/**
 * __usePetitionTypesQuery__
 *
 * To run a query within a React component, call `usePetitionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetitionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetitionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePetitionTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PetitionTypesQuery, PetitionTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<PetitionTypesQuery, PetitionTypesQueryVariables>(PetitionTypesDocument, baseOptions);
      }
export function usePetitionTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PetitionTypesQuery, PetitionTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PetitionTypesQuery, PetitionTypesQueryVariables>(PetitionTypesDocument, baseOptions);
        }
export type PetitionTypesQueryHookResult = ReturnType<typeof usePetitionTypesQuery>;
export type PetitionTypesLazyQueryHookResult = ReturnType<typeof usePetitionTypesLazyQuery>;
export type PetitionTypesQueryResult = ApolloReactCommon.QueryResult<PetitionTypesQuery, PetitionTypesQueryVariables>;
export const PetitionTypeDocument = gql`
    query petitionType($id: RelayID!) {
  petitionType(id: $id) {
    code
  }
}
    `;

/**
 * __usePetitionTypeQuery__
 *
 * To run a query within a React component, call `usePetitionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetitionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetitionTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePetitionTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PetitionTypeQuery, PetitionTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<PetitionTypeQuery, PetitionTypeQueryVariables>(PetitionTypeDocument, baseOptions);
      }
export function usePetitionTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PetitionTypeQuery, PetitionTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PetitionTypeQuery, PetitionTypeQueryVariables>(PetitionTypeDocument, baseOptions);
        }
export type PetitionTypeQueryHookResult = ReturnType<typeof usePetitionTypeQuery>;
export type PetitionTypeLazyQueryHookResult = ReturnType<typeof usePetitionTypeLazyQuery>;
export type PetitionTypeQueryResult = ApolloReactCommon.QueryResult<PetitionTypeQuery, PetitionTypeQueryVariables>;
export const ClientsDocument = gql`
    query clients {
  clients {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, baseOptions);
      }
export function useClientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, baseOptions);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = ApolloReactCommon.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const ClientPetitionsDocument = gql`
    query clientPetitions($id: RelayID!) {
  client(id: $id) {
    name
    petitions {
      edges {
        node {
          id
          type {
            name
          }
          project {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useClientPetitionsQuery__
 *
 * To run a query within a React component, call `useClientPetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPetitionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientPetitionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClientPetitionsQuery, ClientPetitionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClientPetitionsQuery, ClientPetitionsQueryVariables>(ClientPetitionsDocument, baseOptions);
      }
export function useClientPetitionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientPetitionsQuery, ClientPetitionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClientPetitionsQuery, ClientPetitionsQueryVariables>(ClientPetitionsDocument, baseOptions);
        }
export type ClientPetitionsQueryHookResult = ReturnType<typeof useClientPetitionsQuery>;
export type ClientPetitionsLazyQueryHookResult = ReturnType<typeof useClientPetitionsLazyQuery>;
export type ClientPetitionsQueryResult = ApolloReactCommon.QueryResult<ClientPetitionsQuery, ClientPetitionsQueryVariables>;
export const PetitionsDocument = gql`
    query petitions {
  petitions {
    edges {
      node {
        ...petitionFrag
      }
    }
  }
}
    ${PetitionFragFragmentDoc}`;

/**
 * __usePetitionsQuery__
 *
 * To run a query within a React component, call `usePetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePetitionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PetitionsQuery, PetitionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PetitionsQuery, PetitionsQueryVariables>(PetitionsDocument, baseOptions);
      }
export function usePetitionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PetitionsQuery, PetitionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PetitionsQuery, PetitionsQueryVariables>(PetitionsDocument, baseOptions);
        }
export type PetitionsQueryHookResult = ReturnType<typeof usePetitionsQuery>;
export type PetitionsLazyQueryHookResult = ReturnType<typeof usePetitionsLazyQuery>;
export type PetitionsQueryResult = ApolloReactCommon.QueryResult<PetitionsQuery, PetitionsQueryVariables>;
export const ManagePetitionDocument = gql`
    query managePetition($id: RelayID!) {
  petition(id: $id) {
    id
    start
    end
    ...intervalsNamesAndSkillsFragment
  }
}
    ${IntervalsNamesAndSkillsFragmentFragmentDoc}`;

/**
 * __useManagePetitionQuery__
 *
 * To run a query within a React component, call `useManagePetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagePetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagePetitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManagePetitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ManagePetitionQuery, ManagePetitionQueryVariables>) {
        return ApolloReactHooks.useQuery<ManagePetitionQuery, ManagePetitionQueryVariables>(ManagePetitionDocument, baseOptions);
      }
export function useManagePetitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManagePetitionQuery, ManagePetitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ManagePetitionQuery, ManagePetitionQueryVariables>(ManagePetitionDocument, baseOptions);
        }
export type ManagePetitionQueryHookResult = ReturnType<typeof useManagePetitionQuery>;
export type ManagePetitionLazyQueryHookResult = ReturnType<typeof useManagePetitionLazyQuery>;
export type ManagePetitionQueryResult = ApolloReactCommon.QueryResult<ManagePetitionQuery, ManagePetitionQueryVariables>;
export const PetitionDetailsDocument = gql`
    query petitionDetails($id: RelayID!) {
  petition(id: $id) {
    id
    code
    status
    documents
    project {
      name
    }
    experts {
      min
    }
    deadLine
    end
    ...commentsFragment
    ...intervalsFragment
  }
}
    ${CommentsFragmentFragmentDoc}
${IntervalsFragmentFragmentDoc}`;

/**
 * __usePetitionDetailsQuery__
 *
 * To run a query within a React component, call `usePetitionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetitionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetitionDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePetitionDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PetitionDetailsQuery, PetitionDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<PetitionDetailsQuery, PetitionDetailsQueryVariables>(PetitionDetailsDocument, baseOptions);
      }
export function usePetitionDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PetitionDetailsQuery, PetitionDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PetitionDetailsQuery, PetitionDetailsQueryVariables>(PetitionDetailsDocument, baseOptions);
        }
export type PetitionDetailsQueryHookResult = ReturnType<typeof usePetitionDetailsQuery>;
export type PetitionDetailsLazyQueryHookResult = ReturnType<typeof usePetitionDetailsLazyQuery>;
export type PetitionDetailsQueryResult = ApolloReactCommon.QueryResult<PetitionDetailsQuery, PetitionDetailsQueryVariables>;
export const CreateDraftPetitionDocument = gql`
    mutation createDraftPetition($input: PetitionInput!) {
  createPetition(input: $input) {
    clientMutationId
    petition {
      ...ClientProjectPriority
    }
  }
}
    ${ClientProjectPriorityFragmentDoc}`;
export type CreateDraftPetitionMutationFn = ApolloReactCommon.MutationFunction<CreateDraftPetitionMutation, CreateDraftPetitionMutationVariables>;

/**
 * __useCreateDraftPetitionMutation__
 *
 * To run a mutation, you first call `useCreateDraftPetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftPetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftPetitionMutation, { data, loading, error }] = useCreateDraftPetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftPetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDraftPetitionMutation, CreateDraftPetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDraftPetitionMutation, CreateDraftPetitionMutationVariables>(CreateDraftPetitionDocument, baseOptions);
      }
export type CreateDraftPetitionMutationHookResult = ReturnType<typeof useCreateDraftPetitionMutation>;
export type CreateDraftPetitionMutationResult = ApolloReactCommon.MutationResult<CreateDraftPetitionMutation>;
export type CreateDraftPetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDraftPetitionMutation, CreateDraftPetitionMutationVariables>;
export const CreateProjectAssignmentDocument = gql`
    mutation createProjectAssignment($input: ProjectAssignmentInput!, $draft: RelayID!) {
  createProjectAssignment(input: $input, draft: $draft) {
    petition {
      id
    }
  }
}
    `;
export type CreateProjectAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateProjectAssignmentMutation, CreateProjectAssignmentMutationVariables>;

/**
 * __useCreateProjectAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateProjectAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectAssignmentMutation, { data, loading, error }] = useCreateProjectAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      draft: // value for 'draft'
 *   },
 * });
 */
export function useCreateProjectAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectAssignmentMutation, CreateProjectAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectAssignmentMutation, CreateProjectAssignmentMutationVariables>(CreateProjectAssignmentDocument, baseOptions);
      }
export type CreateProjectAssignmentMutationHookResult = ReturnType<typeof useCreateProjectAssignmentMutation>;
export type CreateProjectAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateProjectAssignmentMutation>;
export type CreateProjectAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectAssignmentMutation, CreateProjectAssignmentMutationVariables>;
export const ProjectNameDocument = gql`
    query ProjectName($id: RelayID!) {
  petition(id: $id) {
    project {
      name
    }
  }
}
    `;

/**
 * __useProjectNameQuery__
 *
 * To run a query within a React component, call `useProjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectNameQuery, ProjectNameQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectNameQuery, ProjectNameQueryVariables>(ProjectNameDocument, baseOptions);
      }
export function useProjectNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectNameQuery, ProjectNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectNameQuery, ProjectNameQueryVariables>(ProjectNameDocument, baseOptions);
        }
export type ProjectNameQueryHookResult = ReturnType<typeof useProjectNameQuery>;
export type ProjectNameLazyQueryHookResult = ReturnType<typeof useProjectNameLazyQuery>;
export type ProjectNameQueryResult = ApolloReactCommon.QueryResult<ProjectNameQuery, ProjectNameQueryVariables>;
export const AddPetitonActionDocument = gql`
    mutation addPetitonAction($input: ActionInput!, $draft: RelayID!) {
  addAction(draft: $draft, input: $input)
}
    `;
export type AddPetitonActionMutationFn = ApolloReactCommon.MutationFunction<AddPetitonActionMutation, AddPetitonActionMutationVariables>;

/**
 * __useAddPetitonActionMutation__
 *
 * To run a mutation, you first call `useAddPetitonActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPetitonActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPetitonActionMutation, { data, loading, error }] = useAddPetitonActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      draft: // value for 'draft'
 *   },
 * });
 */
export function useAddPetitonActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPetitonActionMutation, AddPetitonActionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPetitonActionMutation, AddPetitonActionMutationVariables>(AddPetitonActionDocument, baseOptions);
      }
export type AddPetitonActionMutationHookResult = ReturnType<typeof useAddPetitonActionMutation>;
export type AddPetitonActionMutationResult = ApolloReactCommon.MutationResult<AddPetitonActionMutation>;
export type AddPetitonActionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPetitonActionMutation, AddPetitonActionMutationVariables>;
export const StepSummaryDocument = gql`
    query StepSummary($id: RelayID!) {
  petition(id: $id) {
    experts {
      min
    }
    project {
      name
    }
    end
    deadLine
    intervals {
      edges {
        node {
          knowledge {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStepSummaryQuery__
 *
 * To run a query within a React component, call `useStepSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStepSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StepSummaryQuery, StepSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<StepSummaryQuery, StepSummaryQueryVariables>(StepSummaryDocument, baseOptions);
      }
export function useStepSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StepSummaryQuery, StepSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StepSummaryQuery, StepSummaryQueryVariables>(StepSummaryDocument, baseOptions);
        }
export type StepSummaryQueryHookResult = ReturnType<typeof useStepSummaryQuery>;
export type StepSummaryLazyQueryHookResult = ReturnType<typeof useStepSummaryLazyQuery>;
export type StepSummaryQueryResult = ApolloReactCommon.QueryResult<StepSummaryQuery, StepSummaryQueryVariables>;
export const CategoriesAndSkillsDocument = gql`
    query categoriesAndSkills($id: RelayID!) {
  specialistCategory
  singleKnowledge(id: $id) {
    id
    skills {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCategoriesAndSkillsQuery__
 *
 * To run a query within a React component, call `useCategoriesAndSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesAndSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesAndSkillsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoriesAndSkillsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesAndSkillsQuery, CategoriesAndSkillsQueryVariables>) {
        return ApolloReactHooks.useQuery<CategoriesAndSkillsQuery, CategoriesAndSkillsQueryVariables>(CategoriesAndSkillsDocument, baseOptions);
      }
export function useCategoriesAndSkillsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesAndSkillsQuery, CategoriesAndSkillsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CategoriesAndSkillsQuery, CategoriesAndSkillsQueryVariables>(CategoriesAndSkillsDocument, baseOptions);
        }
export type CategoriesAndSkillsQueryHookResult = ReturnType<typeof useCategoriesAndSkillsQuery>;
export type CategoriesAndSkillsLazyQueryHookResult = ReturnType<typeof useCategoriesAndSkillsLazyQuery>;
export type CategoriesAndSkillsQueryResult = ApolloReactCommon.QueryResult<CategoriesAndSkillsQuery, CategoriesAndSkillsQueryVariables>;
export const SuggestSpecialistDocument = gql`
    query suggestSpecialist($input: SuggestSpecialistInput!) {
  suggestSpecialists(input: $input) {
    user {
      id
      name
      category
    }
    points {
      skillsMatch
      assignamentDays
    }
  }
}
    `;

/**
 * __useSuggestSpecialistQuery__
 *
 * To run a query within a React component, call `useSuggestSpecialistQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestSpecialistQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestSpecialistQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestSpecialistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuggestSpecialistQuery, SuggestSpecialistQueryVariables>) {
        return ApolloReactHooks.useQuery<SuggestSpecialistQuery, SuggestSpecialistQueryVariables>(SuggestSpecialistDocument, baseOptions);
      }
export function useSuggestSpecialistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuggestSpecialistQuery, SuggestSpecialistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuggestSpecialistQuery, SuggestSpecialistQueryVariables>(SuggestSpecialistDocument, baseOptions);
        }
export type SuggestSpecialistQueryHookResult = ReturnType<typeof useSuggestSpecialistQuery>;
export type SuggestSpecialistLazyQueryHookResult = ReturnType<typeof useSuggestSpecialistLazyQuery>;
export type SuggestSpecialistQueryResult = ApolloReactCommon.QueryResult<SuggestSpecialistQuery, SuggestSpecialistQueryVariables>;
export const AddProposalToPetitionDocument = gql`
    mutation addProposalToPetition($petition: RelayID!, $input: ProposalInput!) {
  createProposalForPetition(petition: $petition, input: $input)
}
    `;
export type AddProposalToPetitionMutationFn = ApolloReactCommon.MutationFunction<AddProposalToPetitionMutation, AddProposalToPetitionMutationVariables>;

/**
 * __useAddProposalToPetitionMutation__
 *
 * To run a mutation, you first call `useAddProposalToPetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProposalToPetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProposalToPetitionMutation, { data, loading, error }] = useAddProposalToPetitionMutation({
 *   variables: {
 *      petition: // value for 'petition'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProposalToPetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProposalToPetitionMutation, AddProposalToPetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddProposalToPetitionMutation, AddProposalToPetitionMutationVariables>(AddProposalToPetitionDocument, baseOptions);
      }
export type AddProposalToPetitionMutationHookResult = ReturnType<typeof useAddProposalToPetitionMutation>;
export type AddProposalToPetitionMutationResult = ApolloReactCommon.MutationResult<AddProposalToPetitionMutation>;
export type AddProposalToPetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProposalToPetitionMutation, AddProposalToPetitionMutationVariables>;
export const AprovePetitionDocument = gql`
    mutation aprovePetition($id: RelayID!) {
  aprovePetition(id: $id)
}
    `;
export type AprovePetitionMutationFn = ApolloReactCommon.MutationFunction<AprovePetitionMutation, AprovePetitionMutationVariables>;

/**
 * __useAprovePetitionMutation__
 *
 * To run a mutation, you first call `useAprovePetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAprovePetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aprovePetitionMutation, { data, loading, error }] = useAprovePetitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAprovePetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AprovePetitionMutation, AprovePetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<AprovePetitionMutation, AprovePetitionMutationVariables>(AprovePetitionDocument, baseOptions);
      }
export type AprovePetitionMutationHookResult = ReturnType<typeof useAprovePetitionMutation>;
export type AprovePetitionMutationResult = ApolloReactCommon.MutationResult<AprovePetitionMutation>;
export type AprovePetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<AprovePetitionMutation, AprovePetitionMutationVariables>;
export const RejectPetitionDocument = gql`
    mutation rejectPetition($id: RelayID!, $comment: String!) {
  rejectPetition(id: $id, comment: $comment)
}
    `;
export type RejectPetitionMutationFn = ApolloReactCommon.MutationFunction<RejectPetitionMutation, RejectPetitionMutationVariables>;

/**
 * __useRejectPetitionMutation__
 *
 * To run a mutation, you first call `useRejectPetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPetitionMutation, { data, loading, error }] = useRejectPetitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useRejectPetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectPetitionMutation, RejectPetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectPetitionMutation, RejectPetitionMutationVariables>(RejectPetitionDocument, baseOptions);
      }
export type RejectPetitionMutationHookResult = ReturnType<typeof useRejectPetitionMutation>;
export type RejectPetitionMutationResult = ApolloReactCommon.MutationResult<RejectPetitionMutation>;
export type RejectPetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectPetitionMutation, RejectPetitionMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: UserInput!) {
  createUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ResumenDataDocument = gql`
    query resumenData($id: RelayID!) {
  petition(id: $id) {
    start
    end
    proposals(filter: {status: {eq: "PENDING"}}) {
      edges {
        node {
          id
          assignaments {
            edges {
              node {
                id
                expert {
                  id
                  name
                  category
                }
                days
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useResumenDataQuery__
 *
 * To run a query within a React component, call `useResumenDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumenDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumenDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumenDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResumenDataQuery, ResumenDataQueryVariables>) {
        return ApolloReactHooks.useQuery<ResumenDataQuery, ResumenDataQueryVariables>(ResumenDataDocument, baseOptions);
      }
export function useResumenDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResumenDataQuery, ResumenDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResumenDataQuery, ResumenDataQueryVariables>(ResumenDataDocument, baseOptions);
        }
export type ResumenDataQueryHookResult = ReturnType<typeof useResumenDataQuery>;
export type ResumenDataLazyQueryHookResult = ReturnType<typeof useResumenDataLazyQuery>;
export type ResumenDataQueryResult = ApolloReactCommon.QueryResult<ResumenDataQuery, ResumenDataQueryVariables>;
export const SingleKnowledgeByIdDocument = gql`
    query SingleKnowledgeByID($id: RelayID!) {
  singleKnowledge(id: $id) {
    name
  }
}
    `;

/**
 * __useSingleKnowledgeByIdQuery__
 *
 * To run a query within a React component, call `useSingleKnowledgeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleKnowledgeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleKnowledgeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleKnowledgeByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SingleKnowledgeByIdQuery, SingleKnowledgeByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<SingleKnowledgeByIdQuery, SingleKnowledgeByIdQueryVariables>(SingleKnowledgeByIdDocument, baseOptions);
      }
export function useSingleKnowledgeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleKnowledgeByIdQuery, SingleKnowledgeByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SingleKnowledgeByIdQuery, SingleKnowledgeByIdQueryVariables>(SingleKnowledgeByIdDocument, baseOptions);
        }
export type SingleKnowledgeByIdQueryHookResult = ReturnType<typeof useSingleKnowledgeByIdQuery>;
export type SingleKnowledgeByIdLazyQueryHookResult = ReturnType<typeof useSingleKnowledgeByIdLazyQuery>;
export type SingleKnowledgeByIdQueryResult = ApolloReactCommon.QueryResult<SingleKnowledgeByIdQuery, SingleKnowledgeByIdQueryVariables>;
export const KnowledgeSkillsDocument = gql`
    query KnowledgeSkills {
  knowledge {
    edges {
      node {
        ...singleKnowledge
        ...skills
      }
    }
  }
  specialistCategory
}
    ${SingleKnowledgeFragmentDoc}
${SkillsFragmentDoc}`;

/**
 * __useKnowledgeSkillsQuery__
 *
 * To run a query within a React component, call `useKnowledgeSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKnowledgeSkillsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KnowledgeSkillsQuery, KnowledgeSkillsQueryVariables>) {
        return ApolloReactHooks.useQuery<KnowledgeSkillsQuery, KnowledgeSkillsQueryVariables>(KnowledgeSkillsDocument, baseOptions);
      }
export function useKnowledgeSkillsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KnowledgeSkillsQuery, KnowledgeSkillsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KnowledgeSkillsQuery, KnowledgeSkillsQueryVariables>(KnowledgeSkillsDocument, baseOptions);
        }
export type KnowledgeSkillsQueryHookResult = ReturnType<typeof useKnowledgeSkillsQuery>;
export type KnowledgeSkillsLazyQueryHookResult = ReturnType<typeof useKnowledgeSkillsLazyQuery>;
export type KnowledgeSkillsQueryResult = ApolloReactCommon.QueryResult<KnowledgeSkillsQuery, KnowledgeSkillsQueryVariables>;
export const SkillsAndCategoriesDocument = gql`
    query SkillsAndCategories {
  skills {
    edges {
      node {
        id
        name
      }
    }
  }
  specialistCategory
}
    `;

/**
 * __useSkillsAndCategoriesQuery__
 *
 * To run a query within a React component, call `useSkillsAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsAndCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillsAndCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SkillsAndCategoriesQuery, SkillsAndCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<SkillsAndCategoriesQuery, SkillsAndCategoriesQueryVariables>(SkillsAndCategoriesDocument, baseOptions);
      }
export function useSkillsAndCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SkillsAndCategoriesQuery, SkillsAndCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SkillsAndCategoriesQuery, SkillsAndCategoriesQueryVariables>(SkillsAndCategoriesDocument, baseOptions);
        }
export type SkillsAndCategoriesQueryHookResult = ReturnType<typeof useSkillsAndCategoriesQuery>;
export type SkillsAndCategoriesLazyQueryHookResult = ReturnType<typeof useSkillsAndCategoriesLazyQuery>;
export type SkillsAndCategoriesQueryResult = ApolloReactCommon.QueryResult<SkillsAndCategoriesQuery, SkillsAndCategoriesQueryVariables>;