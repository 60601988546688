import moment from 'moment'

declare module 'moment' {
  interface Moment {
    businessAdd(days: number): Moment
  }
}

moment.fn.businessAdd = function businessAdd(days) {
  const signal = days < 0 ? -1 : 1
  const absDays = Math.abs(days)
  const d = this.clone().add(Math.floor(absDays / 5) * 7 * signal, 'd')
  let remaining = absDays % 5
  while (remaining) {
    d.add(signal, 'd')
    if (d.day() !== 0 && d.day() !== 6) remaining -= 1
  }
  return d
}

export default moment
