import { usePetitionMutation } from '@Hooks/petitions'
import { routes } from '@Routes/routes'
import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import Stepper from './Stepper'
import steps from './Types/ProjectAssignament/steps'

type PetitionLocation = {
  id: string
  type: string
}

const New = () => {
  const { state } = useLocation<PetitionLocation>()
  const { createProjectAssignament } = usePetitionMutation(state.id)

  if (!state) {
    return <Redirect to="/petitions/new" />
  }

  return (
    <Stepper
      id={state.id}
      steps={steps}
      formikProps={{ validateOnBlur: false }}
      onSubmit={createProjectAssignament(routes.petitionsList)}
    />
  )
}

export default New
