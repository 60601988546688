import React from 'react'
import ProjectAssignment from './ProjectAssignment'
import ProposalAssignment from './ProposalAssignment'
import TecnicalAssistance from './TecnicalAssistance'
import TicketExtension from './TicketExtension'

const NEXT_FIELDS: { [key: string]: JSX.Element } = {
  ASIGNPY: <ProjectAssignment />,
  ASIGNPR: <ProposalAssignment />,
  ASITTEC: <TecnicalAssistance />,
  AMP: <TicketExtension />,
}

const NextFields = ({ code }: { code?: string }) => {
  if (!code) {
    return <></>
  }
  return NEXT_FIELDS[code]
}

export default NextFields
