import StepSummary from '@Components/StepSummary'
import Upload from '@Components/Upload'
import { useProjectNameQuery } from '@Generated/staffing-hooks'
import GetAreas from '@Utils/getKnowledge'
import { Col, Row } from 'antd'
import { Form, Input } from 'formik-antd'
import { useFormikWizard } from 'formik-wizard'
import React from 'react'
import './index.less'
import { useHistory } from 'react-router-dom'

const Documentation = () => {
  const { values } = useFormikWizard()
  const { location } = useHistory()
  const { data } = useProjectNameQuery({ variables: { id: location.state.id } })

  return (
    <Row className="sf-asign-project-doc">
      <StepSummary
        endDate={values.configuration.endDate}
        teamSize={values.configuration.team.length}
        knowledgeAreas={GetAreas(values.configuration.team)}
        link={data?.petition.project?.name}
      />
      <Row
        className="sf-asign-project-doc-body"
        type="flex"
        justify="space-around"
        gutter={[16, 8]}
      >
        <Col span={12}>
          <Form.Item name="comment" className="sf-text-area">
            <Input.TextArea name="comment" placeholder="Añade un comentario" />
          </Form.Item>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Upload accept="application/pdf" />
        </Col>
      </Row>
    </Row>
  )
}

export default Documentation
