import Alert from '@Components/Alert'
import Loading from '@Components/Loading'
import StepSummary from '@Components/StepSummary'
import { DownloadFile } from '@Components/Upload'
import {
  ActionType,
  PetitionDetailsQuery,
  useAddPetitonActionMutation,
  usePetitionDetailsQuery,
  Maybe,
} from '@Generated/staffing-hooks'
import { useAuth } from '@Hooks/auth'
import { routes } from '@Routes/routes'
import { GetAreasTeam } from '@Utils/getKnowledge'
import { Button, Col, Input, Modal, Row, Table, Tag, Typography } from 'antd'
import UploadList from 'antd/lib/upload/UploadList'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import uniqid from 'uniqid'
import { columns, commentColumns } from './columns'
import './index.less'

type Intervals = PetitionDetailsQuery['petition']['intervals']
const getData = (intervals: Intervals) =>
  intervals.edges.map((interval, idk) => ({
    key: idk,
    knowledge: interval.node.knowledge.name,
    skills: interval.node.skills.edges.map(skill => (
      <Tag key={uniqid()}>{skill.node.name}</Tag>
    )),
    category: interval.node.category,
    experts:
      interval.node.min === interval.node.max
        ? interval.node.min
        : `${interval.node.min} - ${interval.node.max}`,
    days: interval.node.duration,
    assignamentStart: moment(interval.node.start).format('L'),
  }))

type Comments = PetitionDetailsQuery['petition']['timeline']
const getComments = (comments: Comments) =>
  comments.map((comment, idk) => ({
    key: idk,
    type: comment.type,
    name: comment.user.name,
    comment: comment.comment,
    date: moment(comment.createdAt).format('L'),
  }))

type IComments = {
  key: number
  type: ActionType
  name: string
  comment: Maybe<string>
  date: string
}

const PetitionDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, refetch } = usePetitionDetailsQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  })

  const history = useHistory()
  const { roles } = useAuth()
  const [addComment] = useAddPetitonActionMutation()
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState<IComments[]>()

  const handleCancel = () => {
    setComment('')
    setOpen(!open)
  }

  const handleOk = () => {
    addComment({
      variables: {
        draft: id,
        input: {
          type: ActionType.COMMENT,
          comment,
        },
      },
    })
    refetch({ id })
    setComment('')
    setOpen(!open)
  }

  useEffect(() => {
    if (data) {
      setComments(getComments(data.petition.timeline))
    }
  }, [comment, data])

  if (loading) {
    return <Loading />
  }

  if (data && data.petition.project) {
    return (
      <>
        <Row type="flex" justify="center">
          <Typography.Title
            style={{
              fontSize: '24px',
              paddingTop: '25px',
              marginBottom: '25px',
            }}
          >
            {`${data.petition.code} / ${data.petition.project.name}`}
          </Typography.Title>
        </Row>
        <Alert id={id || ''} />
        <StepSummary
          endDate={
            data.petition.deadLine ? data.petition.deadLine : data.petition.end
          }
          teamSize={data.petition.experts?.min}
          link={data.petition.project.name}
          knowledgeAreas={GetAreasTeam(data.petition.intervals.edges)}
        />
        <Table
          columns={columns}
          dataSource={getData(data.petition.intervals)}
          pagination={false}
          scroll={{ y: 240 }}
        />
        <Row
          type="flex"
          justify="space-between"
          style={{ marginTop: 'auto', marginBottom: '2.5%' }}
        >
          <Col span={14} offset={10} style={{ marginTop: '20px' }}>
            <Button onClick={() => setOpen(!open)}>Añadir comentario</Button>
            <Modal
              visible={open}
              closable={false}
              title={
                <Row type="flex" justify="center" className="sf-title-row">
                  Añadir comentario
                </Row>
              }
              footer={
                <Row type="flex" justify="center">
                  <Button
                    key="back"
                    type="link"
                    className="sf-on-cancel"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="link"
                    key="submit"
                    onClick={handleOk}
                    disabled={comment.length < 10}
                  >
                    Añadir
                  </Button>
                </Row>
              }
            >
              <Input.TextArea
                className="sf-text-area-height"
                placeholder="Comentario"
                value={comment}
                onChange={event => setComment(event.target.value)}
              />
            </Modal>
          </Col>
          <Row type="flex" justify="center" style={{ marginTop: '2%' }}>
            <Col span={14}>
              <Table
                columns={commentColumns}
                dataSource={comments}
                showHeader={false}
                pagination={false}
                scroll={{ y: 174 }}
              />
            </Col>
            <Col span={6}>
              <UploadList
                onPreview={DownloadFile}
                showRemoveIcon={false}
                onDownload={DownloadFile}
                listType="text"
                locale={{}}
                items={data.petition.documents?.map(document => ({
                  url: `files/${document}`,
                  uid: document,
                  name: document.substring(
                    document.lastIndexOf('-') + 1,
                    document.length
                  ),
                  size: document.length,
                  type: `aplication/${document.substring(
                    document.lastIndexOf('.') + 1,
                    document.length
                  )}`,
                  status: 'done',
                }))}
              />
            </Col>
            <Col span={4}>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{ height: ' 100%' }}
              >
                {data.petition.status === 'PENDING_TO_ASSIGNAMENT' &&
                  roles.includes('manager') && (
                    <Button
                      type="primary"
                      onClick={() => {
                        if (id) {
                          history.push(
                            routes.petitionManage.replace(':id', id),
                            {
                              id,
                            }
                          )
                        }
                      }}
                    >
                      Empezar
                    </Button>
                  )}
                {data.petition.status === 'PENDING_TO_REVIEW_PROPOSAL' &&
                  roles.includes('petitioner') && (
                    <Button
                      type="primary"
                      onClick={() => {
                        if (id) {
                          history.push(routes.petitionAccept, {
                            id,
                          })
                        }
                      }}
                    >
                      Ver
                    </Button>
                  )}
              </Row>
            </Col>
          </Row>
        </Row>
      </>
    )
  }
  return <></>
}

export default PetitionDetails
