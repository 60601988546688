import React from 'react'
import { useFormikContext } from 'formik'
import Input from '../Input'
import LinkProject from '../LinkProject'
import RateInput from '../RateInput'

const INPUTS = [
  {
    label: 'Proyecto',
    name: 'project',
    placeholder: 'Nombra tu proyecto',
    required: true,
    defaultValue: null,
    validate: (value: string) => (value ? undefined : 'Campo obligatorio'),
  },
  {
    label: 'Código',
    name: 'code',
    placeholder: 'Introduce el código del proyecto',
    required: false,
    defaultValue: null,
    validate: () => undefined,
  },
]

const ProjectAssignment = () => {
  const { values } = useFormikContext()

  return (
    <>
      {values.client && (
        <>
          {INPUTS.map(e => (
            <Input
              key={e.name}
              required={e.required}
              label={e.label}
              name={e.name}
              placeholder={e.placeholder}
              defaultValue={e.defaultValue}
            />
          ))}
          <RateInput label="Urgencia" name="priority" quantity={4} />
          <LinkProject />
        </>
      )}
    </>
  )
}

export default ProjectAssignment
