import React from 'react'
import { Row, Spin } from 'antd'

const Loading = () => {
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{
        height: '100vh',
      }}
    >
      <Spin size="large" />
    </Row>
  )
}

export default Loading
