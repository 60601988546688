import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import PrivateRoute from '@Components/PrivateRoute'
import client from '@Utils/apollo-client'
import history from '@Utils/history'
import Authenticate from '@Views/Authenticate'
import { I18n } from 'react-polyglot'
import './index.less'
import translations from '@Assets/es'
import { AuthProvider } from '@Context/auth'
import moment from 'moment'
import 'moment/locale/es'
import App from './App'

moment.locale('es')
declare global {
  interface Window {
    locale: any
  }
}

const locale = window.locale || 'es'

const ApolloApp = () => {
  return (
    <Router history={history}>
      <AuthProvider>
        <ApolloProvider client={client}>
          <Switch>
            <Route path="/authenticate" component={Authenticate} />
            <I18n locale={locale} messages={translations}>
              <PrivateRoute component={App} />
            </I18n>
          </Switch>
        </ApolloProvider>
      </AuthProvider>
    </Router>
  )
}

ReactDOM.render(<ApolloApp />, document.getElementById('root'))
