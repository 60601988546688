import React from 'react'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import { Button } from 'antd'
import * as Yup from 'yup'
import './index.less'
import { useRegisterMutation } from '@Generated/staffing-hooks'
import { useHistory } from 'react-router-dom'
import { routes } from '@Routes/routes'

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
}

export type InitialValues = typeof initialValues

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Campo obligatorio'),
  lastName: Yup.string().required('Campo obligatorio'),
  email: Yup.string()
    .email('Email inválido')
    .required('Campo obligatorio'),
  password: Yup.string()
    .label('Password')
    .required('Campo obligatorio')
    .min(8, 'Demasiado corta')
    .max(22, 'Demasiado larga'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Las contraseñas deben de ser iguales'
  ),
})

const Singup = () => {
  const [regiter] = useRegisterMutation()
  const history = useHistory()
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { confirmPassword: _, ...input } = values
          await regiter({ variables: { input } })
          history.replace(routes.base)
        } catch {
          actions.setFieldError('email', 'Email inválido')
        }
      }}
    >
      {() => (
        <Form id="sf-register-form">
          <Form.Item name="firstName">
            <Input name="firstName" placeholder="Nombre" />
          </Form.Item>
          <Form.Item name="lastName">
            <Input name="lastName" placeholder="Apellidos" />
          </Form.Item>
          <Form.Item name="email">
            <Input name="email" placeholder="Email" autoComplete="username" />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              name="password"
              placeholder="Contraseña"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item name="confirmPassword">
            <Input.Password
              name="confirmPassword"
              placeholder="Confirmar contraseña"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item name="submit">
            <Button htmlType="submit" type="primary">
              Crear cuenta nueva
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  )
}

Singup.typeName = 'SingupForm'

export default Singup
