import React from 'react'
import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { useLocation, Link } from 'react-router-dom'
import { routes } from '@Routes/routes'
import './breadcrumb.less'

const breadcrumbNameMap: { [index: string]: string } = {
  [routes.petitionsNew]: 'Nueva',
  [routes.petitionsList]: 'Peticiones',
  [routes.petitionManageBase]: 'Gestionar',
  [routes.petitionManage]: 'Petición',
  [routes.petitionDetails]: 'Detalles',
  [routes.petitionAccept]: 'Revisar',
}

const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/

const parseRoute = (url: string, dynamic = ':id') => {
  const splited = url.split('/')
  splited[splited.length - 1] = splited[splited.length - 1].replace(
    base64Regex,
    dynamic
  )

  return splited.join('/')
}

const Breadcrumb = () => {
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter(i => i)

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    return (
      <AntdBreadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[parseRoute(url)]}</Link>
      </AntdBreadcrumb.Item>
    )
  })

  const breadcrumbItems = [
    <AntdBreadcrumb.Item key="home">
      <Link to={routes.base}>Inicio</Link>
    </AntdBreadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return (
    <AntdBreadcrumb className="sf-breadcrumb">{breadcrumbItems}</AntdBreadcrumb>
  )
}

export default Breadcrumb
