import moment from '@Utils/moment-business'
import { yupToFormErrors } from 'formik'
import { FormikWizardStepType } from 'formik-wizard/dist/types'
import uniqid from 'uniqid'
import * as Yup from 'yup'
import Configuration from './Configuration'
import Documetation from './Documentation'
import Resumen from './Resumen'

export const configurationSchema = Yup.object().shape({
  endDate: Yup.date().required('Campo obligatorio'),
  team: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string(),
        area: Yup.string().required('Campo obligatorio'),
        specialist: Yup.string().required('Campo obligatorio'),
        days: Yup.number().required('Campo obligatorio'),
        assignamentStart: Yup.date()
          .required('Campo obligatorio')
          .test('assignamentStart', 'Fechas y días incorrectas', function test(
            value
          ) {
            const { endDate } = this?.options?.context as any
            const { days } = this.parent
            return moment(value)
              .businessAdd(days)
              .isSameOrBefore(endDate, 'days')
          }),
        skills: Yup.array().notRequired(),
      })
    )
    .min(1),
})

export const ResumenSchema = Yup.object().shape({
  comment: Yup.string().required('Campo obligatorio'),
  files: Yup.array(),
})

const steps: FormikWizardStepType[] = [
  {
    id: 'configuration',
    component: Configuration,
    initialValues: {
      endDate: undefined,
      team: [
        {
          key: uniqid(),
          area: undefined,
          specialist: undefined,
          days: undefined,
          assignamentStart: undefined,
          skills: [],
        },
      ],
    },
    validate: async values => {
      let parsedErr = {}
      try {
        await configurationSchema.validate(values, {
          /** this.parent.parent is not allowed when object is in array (steps.ts:23) */
          context: { endDate: values.endDate },
          abortEarly: false,
        })
      } catch (err) {
        parsedErr = yupToFormErrors(err)
      }
      return parsedErr
    },
  },
  {
    id: 'documentation',
    component: Documetation,
    initialValues: {
      comment: undefined,
      files: [],
    },
    validationSchema: ResumenSchema,
  },
  {
    id: 'resumen',
    component: Resumen,
  },
]

export default steps
