import React from 'react'
import { Icon } from 'antd'
import { Form, Rate } from 'formik-antd'

const { Item } = Form

type RateInputProps = {
  name: string
  label: string
  quantity: number
}

const RateInput = (props: RateInputProps) => {
  return (
    <Item label={props.label} name={props.name}>
      <Rate
        name={props.name}
        count={props.quantity}
        character={<Icon theme="filled" type="thunderbolt" />}
      />
    </Item>
  )
}

export default RateInput
