import Description from '@Components/Description'
import Loading from '@Components/Loading'
import {
  useCreateDraftPetitionMutation,
  usePetitionTypesQuery,
} from '@Generated/staffing-hooks'
import { Button, Row, message } from 'antd'
import { Formik } from 'formik'
import { Form, Select } from 'formik-antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ClientSelect from './ClientSelect'
import './index.less'
import { nextInitialValues } from './initialValues'
import NextFields from './nextFields'
import { validationSchema } from './validationSchema'
import { RelatedPetition } from './RelatedModal'

const { Item } = Form

const formItemLayout = {
  labelCol: {
    xs: { span: 28 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

interface PetitionValues {
  ticketType: string
  client: string
  project: string
  proposal: string
  priority: number
  link: boolean
  code: string
  related: RelatedPetition[]
  linked: string
}
type PetitionValuesNullish = Partial<PetitionValues>

const PetitionForm = () => {
  const [initialValues, setInitialValues] = useState<PetitionValuesNullish>({
    ticketType: undefined,
    client: undefined,
  })
  const { data, loading } = usePetitionTypesQuery()
  const [draftPetition] = useCreateDraftPetitionMutation()
  const history = useHistory()

  if (loading) {
    return <Loading />
  }

  return (
    <Row type="flex" className="sf-outter-row">
      <Description
        title="Nueva Petición"
        message="Aquí puedes seleccionar el tipo de ticket para realizar la petición de miembros del equipo, así como realizar una configuración inicial del proyecto"
      />
      <Formik<any>
        initialErrors={{
          ticketType: undefined,
          client: undefined,
        }}
        initialValues={initialValues}
        onSubmit={async (values: PetitionValues) => {
          if (data) {
            const ticketId = data.petitionTypes.edges.find(
              edge => edge.node.code === values.ticketType
            )
            if (ticketId) {
              const { data: res, errors } = await draftPetition({
                variables: {
                  input: {
                    type: ticketId.node.id,
                    client: values.client,
                    priority: values.priority,
                    project: values.project.trim(),
                    code: values.code.trim(),
                    related: values.related.map(e => e.node.id),
                  },
                },
              })
              if (errors) {
                message.config({
                  duration: 4,
                })
                message.error('El nombre del proyecto ya existe')
              } else {
                history.push(
                  `/petitions/new/${values.ticketType.toLowerCase()}`,
                  {
                    id: res?.createPetition.petition.id,
                    type: values.ticketType,
                  }
                )
              }
            }
          }
        }}
        enableReinitialize
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ values, isValid }) => (
          <Form {...formItemLayout}>
            <Row type="flex" justify="center">
              <div>
                <Item label="Tipo de Ticket" name="ticketType" required>
                  <Select
                    name="ticketType"
                    placeholder="Selecciona"
                    loading={loading}
                    style={{ width: 342 }}
                    onChange={code => {
                      setInitialValues(nextInitialValues[code.toString()])
                    }}
                  >
                    {data?.petitionTypes.edges.map(
                      ({ node: { code, name } }) => (
                        <Select.Option key={code}>{name}</Select.Option>
                      )
                    )}
                  </Select>
                </Item>
                {values.ticketType && <ClientSelect />}
                <NextFields code={values.ticketType} />
              </div>
            </Row>
            <Row type="flex" justify="end" style={{ width: '100%' }}>
              <Button
                disabled={!isValid}
                htmlType="submit"
                type="primary"
                className="sf-submit-button"
              >
                Continuar
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  )
}

export default PetitionForm
