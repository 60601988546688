import { useAuth } from '@Hooks/auth'
import { routes } from '@Routes/routes'
import { Button, Icon, Modal, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './index.less'

const Logout = () => {
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const { signOut } = useAuth()
  const logout = () => {
    signOut()
    history.push(routes.authenticate)
  }
  return (
    <>
      <Button type="link" onClick={() => setOpen(true)}>
        <Icon type="poweroff" />
      </Button>
      <Modal
        visible={open}
        centered
        closable={false}
        footer={
          <Row type="flex" justify="center">
            <Button
              key="back"
              type="link"
              className="sf-on-cancel"
              onClick={() => setOpen(false)}
            >
              Volver
            </Button>
            <Button type="link" key="submit" onClick={logout}>
              Logout
            </Button>
          </Row>
        }
      >
        <Row type="flex" justify="center">
          <Typography.Title className="sf-contet-log">
            ¿Seguro que deseas salir de la aplicación?
          </Typography.Title>
        </Row>
      </Modal>
    </>
  )
}

export default Logout
