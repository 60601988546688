import React from 'react'
import { Form, Select } from 'formik-antd'
import { useClientsQuery } from '@Generated/staffing-hooks'
import { ConditionalSelect } from '@Components/ConditionalField'
import { useFormikContext } from 'formik'

const { Item } = Form

const ClientSelect = () => {
  const { setFieldValue } = useFormikContext<any>()
  const { data, loading } = useClientsQuery({ fetchPolicy: 'no-cache' })
  return (
    <Item label="Cliente" name="client" required>
      <ConditionalSelect
        showSearch
        name="client"
        loading={loading}
        placeholder="Nombre del cliente"
        optionFilterProp="children"
        style={{ width: 342 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        defaultValue={null}
        onChange={() => {
          setFieldValue('related', [])
        }}
      >
        {data?.clients.edges.map(({ node: { id, name } }) => {
          return <Select.Option key={id}>{name}</Select.Option>
        })}
      </ConditionalSelect>
    </Item>
  )
}

export default ClientSelect
