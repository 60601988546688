import { useUploadFileMutation } from '@Generated/staffing-hooks'
import { findByField } from '@Utils/helpers'
import { Icon } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import UploadList from 'antd/lib/upload/UploadList'
import download from 'downloadjs'
import reject from 'ramda/es/reject'
import React, { useCallback, useState, useEffect } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useFormikContext } from 'formik'

export type UploadProps = Pick<DropzoneOptions, 'accept'>

export const DownloadFile = async (file: UploadFile<any>) => {
  const [protocol, , host] = window.location.href.split('/')
  const res = await fetch(`${protocol}//${host}/${file.url}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  })
  const blob = await res.blob()
  download(blob, file.name)
}

const Upload = ({ accept }: UploadProps) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const [uploadFile] = useUploadFileMutation()
  const [files, setFiles] = useState<UploadFile[]>(values.files)

  useEffect(() => {
    setFieldValue('files', files)
  }, [files, setFieldValue])

  const onDrop = useCallback(
    async ([file]: File[]) => {
      setFiles(prevFiles => [
        ...prevFiles,
        {
          uid: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          status: 'uploading',
        },
      ])
      try {
        const { data } = await uploadFile({
          variables: { file },
        })
        setFiles(
          findByField<UploadFile>(
            { name: file.name },
            {
              status: 'done',
              url: `files/${data?.uploadFile}`,
              fileName: data?.uploadFile,
            }
          )
        )
      } catch {
        setFiles(
          findByField<UploadFile>({ name: file.name }, { status: 'error' })
        )
      }
    },
    [uploadFile]
  )

  const onRemove = useCallback((file: UploadFile) => {
    setFiles(reject(value => value.name === file.name))
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  })

  return (
    <>
      <div
        {...getRootProps({
          className: 'ant-upload ant-upload-drag',
          style: {
            minHeight: '250px',
          },
        })}
      >
        <span className="ant-upload ant-upload-btn">
          <input {...getInputProps()} />
          <div className="ant-upload-drag-container">
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Arrastrar para adjuntar ficheros</p>
            <p className="ant-upload-hint">o navega para adjuntarlo</p>
          </div>
        </span>
      </div>
      <UploadList
        listType="text"
        locale={{}}
        items={files}
        onDownload={DownloadFile}
        onPreview={DownloadFile}
        onRemove={onRemove}
      />
    </>
  )
}

export default Upload
