import { AssignamentContext } from '@Components/AssignmentCalendar/assignamentContext'
import { gradientColor } from '@Utils/colors'
import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import './candidate.less'
import TypeDay from './TypeDay'

export type CandidateProps = {
  candidateIdx: number
}

const Candidate = ({ candidateIdx }: CandidateProps) => {
  const { calendar } = useContext(AssignamentContext)

  const days = useMemo(
    () =>
      Array.from(
        {
          length:
            calendar.calendarType === 'month'
              ? calendar.currentDate.daysInMonth()
              : 7,
        },
        (_, index) => index + 1
      ),
    [calendar.calendarType, calendar.currentDate]
  )

  return (
    <Row className="candidate">
      <Col span={3} className="text-col">
        {calendar.candidates[candidateIdx].name}
      </Col>
      <Col span={3} className="text-col">
        {calendar.candidates[candidateIdx].rank}
      </Col>
      {calendar.mode === 'editable' ? (
        <Col
          span={3}
          className="text-col"
          style={{
            color: gradientColor(
              calendar.candidates[candidateIdx].coincidenceSkills /
                calendar.maxSkills,
              ['#d9534f', '#f0ad4e', '#5cb85c']
            ),
          }}
        >
          {calendar.candidates[candidateIdx].coincidenceSkills}/
          {calendar.maxSkills}
        </Col>
      ) : (
        <Col span={3} className="text-col" />
      )}

      <Col span={15} className="days-section">
        <Row type="flex" className="day-row">
          {days.map(dayNumber => {
            const colMoment = moment(calendar.currentDate).startOf(
              calendar.calendarType
            )
            colMoment.add(dayNumber - 1, 'day')

            return (
              <TypeDay
                key={colMoment.toString()}
                date={colMoment}
                candidateIdx={candidateIdx}
              />
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}

export default Candidate
