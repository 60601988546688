import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Tag, Typography } from 'antd'
import moment from 'moment'
import AreaName from './AreaName'

const columns: ColumnProps<any>[] = [
  {
    title: 'Área de Conocimiento',
    dataIndex: 'area',
    render: area => <AreaName id={area} />,
  },
  {
    title: 'Skils',
    dataIndex: 'skills',
    render: text => {
      return (
        <>
          {text.map((e: any) => (
            <Tag key={e.node.name}>{e.node.name}</Tag>
          ))}
        </>
      )
    },
  },
  {
    title: 'Specialista',
    dataIndex: 'specialist',
  },
  {
    title: 'Días',
    dataIndex: 'days',
  },
  {
    title: 'Inicio de asignación',
    dataIndex: 'assignamentStart',
    render: record => (
      <Typography>{moment(record).format('DD/MM/YYYY')}</Typography>
    ),
  },
]

export default columns
