import { Row } from 'antd'
import React from 'react'
import CandidateList from './CandidateList'
import Footer from './Footer'
import Header from './Header'
import './index.less'
import AssignamentCalendarProvider, {
  AssignamentCalendarProps,
} from './assignamentContext'

const AssignmentCalendar = (props: AssignamentCalendarProps) => {
  return (
    <AssignamentCalendarProvider value={props}>
      <div className="assignment-calendar">
        <Header />
        <Row>
          <CandidateList />
        </Row>
        <Footer />
      </div>
    </AssignamentCalendarProvider>
  )
}

export default AssignmentCalendar
