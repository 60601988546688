import { MutationResolvers } from '@Generated/staffing-resolvers'

export const localPetitionMutations: MutationResolvers = {
  setCurrentLocalPetition: (_root, { input }, { cache }) => {
    cache.writeData({
      data: {
        currentLocalPetition: {
          __typename: 'LocalPetition',
          code: null,
          ...input,
        },
      },
    })
    return null
  },
}
