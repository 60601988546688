import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Menu as AntMenu, Icon } from 'antd'
import { routes } from '@Routes/routes'

const { SubMenu, Item } = AntMenu

const Menu = () => {
  const location = useLocation()

  return (
    <AntMenu theme="dark" selectedKeys={[location.pathname]} mode="inline">
      <SubMenu
        title={
          <span>
            <Icon type="dashboard" />
            <span>Peticiones</span>
          </span>
        }
      >
        <Item key={routes.petitionsNew}>
          <Link to={routes.petitionsNew}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '16px',
              }}
            >
              <span>Petición nueva</span>
              <Icon type="plus" />
            </span>
          </Link>
        </Item>
        <Item key={routes.petitionsList}>
          <Link to={routes.petitionsList}>
            <span>Consultar Peticiones</span>
          </Link>
        </Item>
      </SubMenu>
      <Item>
        <Icon type="calendar" />
        <span>
          <span>Calendario</span>
        </span>
      </Item>
      <Item>
        <Icon type="info-circle" />
        <span>
          <span>Notificaciones</span>
        </span>
      </Item>
    </AntMenu>
  )
}
export default Menu
