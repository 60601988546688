import { setContext } from 'apollo-link-context'

export const authLink = setContext((_, context) => {
  const authToken = localStorage.getItem('userToken')

  if (authToken) {
    return {
      ...context,
      headers: {
        ...context.headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    }
  }
  return context
})
