/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback } from 'react'
import { Modal, Row, Button, Input } from 'antd'
import {
  useResumenDataQuery,
  useRejectPetitionMutation,
  useAprovePetitionMutation,
  ResumenDataQuery,
} from '@Generated/staffing-hooks'
import { useLocation, useHistory } from 'react-router-dom'
import Alert from '@Components/Alert'
import { routes } from '@Routes/routes'
import moment from 'moment'
import Loading from '@Components/Loading'
import { Formik } from 'formik'
import AssignmentCalendar from '@Components/AssignmentCalendar'

const getCandidates = (data: ResumenDataQuery) =>
  data.petition.proposals.edges
    .map(proposal =>
      proposal.node.assignaments.edges.map(candidate => ({
        id: candidate.node.expert.id,
        name: candidate.node.expert.name,
        rank: candidate.node.expert.category as string,
        coincidenceSkills: 0,
        engagedDays: [],
        selectedDays: candidate.node.days.map(day => moment(day)),
      }))
    )
    .flat()

const AcceptOrDecline = () => {
  const { state } = useLocation()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [rejectPetition] = useRejectPetitionMutation()
  const [aprovePetition] = useAprovePetitionMutation()
  const { data, loading } = useResumenDataQuery({
    variables: { id: state.id },
    fetchPolicy: 'no-cache',
  })

  const handleComment = useCallback(() => {
    rejectPetition({
      variables: {
        id: state.id,
        comment,
      },
    })
    setComment('')
    setOpen(false)
    history.push(routes.petitionsList)
  }, [comment, history, rejectPetition, state.id])

  const handleAproved = useCallback(() => {
    aprovePetition({
      variables: {
        id: state.id,
      },
    })
    history.push(routes.petitionsList)
  }, [aprovePetition, history, state.id])

  if (loading) {
    return <Loading />
  }

  if (data) {
    return (
      <>
        <Alert id={state.id} />
        <Row type="flex" justify="center" style={{ flexDirection: 'column' }}>
          <Formik initialValues={{}} onSubmit={() => undefined}>
            <Row style={{ marginTop: '3%' }}>
              <AssignmentCalendar
                mode="view"
                candidates={getCandidates(data)}
                startDate={moment(data!.petition!.start as string)}
                endDate={moment(data!.petition!.end as string)}
              />
            </Row>
          </Formik>
          <Row type="flex" justify="center" style={{ marginTop: '3%' }}>
            <Button onClick={() => setOpen(true)} style={{ marginRight: '1%' }}>
              Denegar
            </Button>
            <Button type="primary" onClick={handleAproved}>
              Aceptar
            </Button>
          </Row>
        </Row>

        <Modal
          visible={open}
          closable={false}
          title={
            <Row type="flex" justify="center" className="sf-title-row">
              ¿Seguro de que desea rechazar?
            </Row>
          }
          footer={
            <Row type="flex" justify="center">
              <Button
                key="back"
                type="link"
                className="sf-on-cancel"
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
              <Button
                type="link"
                key="submit"
                disabled={comment.length < 20}
                onClick={handleComment}
              >
                Añadir
              </Button>
            </Row>
          }
        >
          <Input.TextArea
            className="sf-text-area-height"
            placeholder="Añadir comentario"
            value={comment}
            onChange={event => setComment(event.target.value)}
          />
        </Modal>
      </>
    )
  }
  return <>Error</>
}

export default AcceptOrDecline
