import React from 'react'
import Layout from '@Components/Layout'
import PrivateRoutes from '@Routes/PrivateRoutes'

const App = () => {
  return (
    <Layout>
      <PrivateRoutes />
    </Layout>
  )
}

export default App
