export const nextInitialValues: { [key: string]: any } = {
  ASIGNPY: {
    ticketType: 'ASIGNPY',
    client: undefined,
    project: undefined,
    code: undefined,
    priority: 0,
    link: false,
    related: [],
  },
  ASIGNPR: {
    ticketType: 'ASIGNPR',
    client: undefined,
    proposal: undefined,
    priority: 0,
    link: false,
    related: [],
  },
  ASITTEC: {
    ticketType: 'ASITTEC',
    client: undefined,
    priority: 4,
    link: false,
    related: [],
  },
  AMP: {
    ticketType: 'AMP',
    client: undefined,
    priority: 0,
    linked: {},
  },
}
