const hexToRgb = (hex: string) => {
  const hexWithoutHashtag = hex.charAt(0) === '#' ? hex.slice(1) : hex
  const red = parseInt(hexWithoutHashtag.substring(0, 2), 16)
  const green = parseInt(hexWithoutHashtag.substring(2, 4), 16)
  const blue = parseInt(hexWithoutHashtag.substring(4, 6), 16)

  return {
    red,
    green,
    blue,
  }
}

const diffColors = (color1: string, color2: string) => {
  const color1Obj = hexToRgb(color1)
  const color2Obj = hexToRgb(color2)

  return {
    red: color1Obj.red - color2Obj.red,
    green: color1Obj.green - color2Obj.green,
    blue: color1Obj.blue - color2Obj.blue,
  }
}

const rgbToHex = (r: number, g: number, b: number) => {
  const intToHex = (c: number) => {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }
  return `#${intToHex(r) + intToHex(g) + intToHex(b)}`
}

export const gradientColor = (
  percent: number,
  colors: string[],
  discrete = false
) => {
  if (Number.isNaN(percent)) {
    return '#333333'
  }
  const factor = percent * (colors.length - 1)

  const idx = Math.trunc(factor)
  const percentGradientColor = factor - idx

  if (discrete || percentGradientColor === 0) {
    return colors[idx]
  }

  const diffColor = diffColors(colors[idx + 1], colors[idx])
  const idxObjColor = hexToRgb(colors[idx])

  return rgbToHex(
    idxObjColor.red + Math.round(diffColor.red * percentGradientColor),
    idxObjColor.green + Math.round(diffColor.green * percentGradientColor),
    idxObjColor.blue + Math.round(diffColor.blue * percentGradientColor)
  )
}
